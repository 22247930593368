import { Injectable } from '@angular/core';
import { AppConstants } from '@app/auth/typings/common-constants';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionCheckService {
  private permissions: string[] = [];
  private permissionsLoaded = false;

  constructor() {
    this.loadPermissions();
  }

  private loadPermissions(): void {
    if (!this.permissionsLoaded) {
      const storedPermissions = JSON.parse(localStorage.getItem(AppConstants.PERMISSIONS)!) || [];
      if (storedPermissions !== null) {
        this.permissions = storedPermissions;
        this.permissionsLoaded = true;
      }
    }
  }

  hasPermission(permission: string): boolean {
    return this.permissions.includes(permission);
  }
}
