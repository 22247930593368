import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { AboutRoutingModule } from './about-routing.module';
import { AboutComponent } from './about.component';
import { CreateLandingPageComponent } from './components/create-landing-page/create-landing-page.component';
import { UpdateLandingPageComponent } from './components/update-landing-page/update-landing-page.component';
import { SharedModule } from '@app/@shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@app/i18n';
import { AuthRoutingModule } from '@app/auth/auth-routing.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatIconModule } from '@angular/material/icon';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SearchPipe } from '@app/shell/search.pipe';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMatTagInputModule } from 'ngx-mat-tag-input';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  imports: [
    CommonModule,
    CommonModule,
    SharedModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    I18nModule,
    AuthRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatDialogModule,
    MaterialFileInputModule,
    MatIconModule,
    AboutRoutingModule,
    AngularEditorModule,
    ColorPickerModule,
    NgxMatTagInputModule,
  ],
  declarations: [AboutComponent, CreateLandingPageComponent, UpdateLandingPageComponent],
})
export class AboutModule {}
