import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginMessageService {
  private loginMessage = new BehaviorSubject<string>('');

  loginMessageObj$ = this.loginMessage.asObservable();

  constructor() {}

  sendLoginMessage(message: string) {
    this.loginMessage.next(message);
  }
}
