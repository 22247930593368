import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionPlanType, Type, UpdateCustomPackCommand } from '../../model/apis';
import { ApisService } from '../../service/apis.service';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-update-custom-pack',
  templateUrl: './update-custom-pack.component.html',
  styleUrls: ['./update-custom-pack.component.scss'],
})
export class UpdateCustomPackComponent implements OnInit, OnDestroy {
  @Input() entryId: number = 0;
  @Output() isUpdateFaqEntry: EventEmitter<boolean> = new EventEmitter(false);

  version: string | null = environment.version;
  error: string | undefined;
  updateCustomPackForm!: FormGroup;
  isLoading = true;
  foundErrorLogin = false;
  statusList: string[];
  customPackStatus: string = '';
  subscriptionList: any[] = [];
  selectedValue: any = null;
  // searchTxt: any = null;
  selecteSubscriptionList: any[] = [];
  toolTipList: string[];
  tempPlanList: any[] = [];

  public searchTxt: FormControl<string | null> = new FormControl<string>('');
  public filteredPlansMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();

  constructor(private formBuilder: FormBuilder, private apisService: ApisService, private modalService: NgbModal) {
    this.createForm();
    this.statusList = ['PUBLISHED', 'UNPUBLISHED'];
    this.toolTipList = [
      'assets/fixed_help_1.png',
      'assets/fixed_help_2.png',
      'assets/fixed_help_3.png',
      'assets/fixed_help_4.png',
      'assets/fixed_help_5.png',
    ];
  }

  ngOnInit(): void {
    this.getCustomPackDetail();

    // load the initial bank list
    // this.filteredPlansMulti.next(this.subscriptionList.slice());

    // listen for search field value changes
    this.searchTxt.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterBanksMulti();
    });
  }

  protected filterBanksMulti() {
    if (!this.subscriptionList) {
      return;
    }
    // get the search keyword
    let search = this.searchTxt.value;
    if (!search) {
      this.filteredPlansMulti.next(this.subscriptionList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the plans by planID or planName
    this.filteredPlansMulti.next(
      this.subscriptionList.filter(
        (bank) =>
          bank.SubscriptionPlanName.toLowerCase().indexOf(search) > -1 ||
          bank.SubscriptionPlanID.toString().toLowerCase().indexOf(search) > -1
      )
    );
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getAvailableSubscriptionPlanDetails() {
    this.selecteSubscriptionList = [];
    let tempPlanList: any[] = this.apisService.getDishPlanList();
    if (tempPlanList.length > 0) {
      tempPlanList.forEach((ele: any) => {
        if (
          ele.SubscriptionPlanType.toUpperCase() === SubscriptionPlanType[SubscriptionPlanType.WATCHOEXCLUSIVES] ||
          ele.SubscriptionPlanType.toUpperCase() === SubscriptionPlanType[SubscriptionPlanType.WATCHOONE]
        ) {
          this.subscriptionList.push(ele);
        }
      });
      if (this.subscriptionList.length > 0) {
        // load the initial bank list
        this.filteredPlansMulti.next(this.subscriptionList.slice());
        this.subscriptionList = this.subscriptionList.sort((a: any, b: any) => {
          return a.SubscriptionGroupNo - b.SubscriptionGroupNo;
        });
        this.subscriptionList.forEach((ele: any) => {
          this.updateCustomPackForm.value.planIds.forEach((element: any) => {
            if (ele.SubscriptionPlanID === element) {
              this.selecteSubscriptionList.push(ele);
            }
          });
        });
      }
    }
  }

  selectItem(event: any) {
    // console.log(event);
    // this.selecteSubscriptionList = [];
    // this.subscriptionList.forEach((ele: any) => {
    //   event.value.forEach((element: any) => {
    //     if (ele.SubscriptionPlanID === element) {
    //       this.selecteSubscriptionList.push(ele);
    //     }
    //   });
    // });
  }

  optionedValue(event: any, subscriptionDetail: any) {
    if (event.isUserInput) {
      if (event.source.selected === false) {
        var index = this.tempPlanList.findIndex((x) => x === subscriptionDetail.SubscriptionPlanID);
        if (index !== -1) {
          this.selecteSubscriptionList.splice(index, 1);
          this.tempPlanList.splice(index, 1);
        }
      } else {
        if (!this.tempPlanList.includes(subscriptionDetail.SubscriptionPlanID)) {
          this.tempPlanList.push(subscriptionDetail.SubscriptionPlanID);
          this.selecteSubscriptionList.push(subscriptionDetail);
        }
      }
    }
  }

  getCustomPackDetail() {
    this.isLoading = true;
    this.apisService.getCustomPackDetailById(this.entryId).subscribe(
      (res) => {
        if (res.data) {
          this.customPackStatus = res.data.customPackStatus;
          this.updateCustomPackForm = this.formBuilder.group({
            title: [res.data.title, Validators.required],
            subTitle: [res.data.subTitle, Validators.required],
            saveUptoText: [res.data.saveUptoText, Validators.required],
            buttonText: [res.data.buttonText, Validators.required],
            displayOrder: [res.data.displayOrder, Validators.required],
            planIds: [res.data.planIds, Validators.required],
            // customPackStatus: [res.data.customPackStatus, Validators.required],
          });
          this.selectedValue = res.data.planIds;
          this.tempPlanList = res.data.planIds;
        }
        this.isLoading = false;
        this.getAvailableSubscriptionPlanDetails();
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  updateCustomPack() {
    this.isLoading = true;
    // let tmpArray: any[] = this.updateCustomPackForm.value.planIds.split(',');
    let command: UpdateCustomPackCommand = {
      title: this.updateCustomPackForm.value.title,
      subTitle: this.updateCustomPackForm.value.subTitle,
      saveUptoText: this.updateCustomPackForm.value.saveUptoText,
      buttonText: this.updateCustomPackForm.value.buttonText,
      displayOrder: this.updateCustomPackForm.value.displayOrder,
      planIds: this.tempPlanList, // tmpArray.map((i: any) => Number(i)),
      customPackStatus: this.customPackStatus,
      id: this.entryId,
      type: Type[Type.CUSTOM],
    };
    if (this.updateCustomPackForm.valid) {
      this.apisService.updateCustomPack(command).subscribe(
        (res) => {
          if (res) {
            this.isUpdateFaqEntry.emit(true);
          }
          // this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    } else {
      this.isLoading = false;
    }
  }

  private createForm() {
    this.updateCustomPackForm = this.formBuilder.group({
      title: ['', Validators.required],
      subTitle: ['', Validators.required],
      saveUptoText: ['', Validators.required],
      buttonText: ['', Validators.required],
      displayOrder: ['', Validators.required],
      planIds: ['', Validators.required],
      // customPackStatus: ['', Validators.required],
    });
  }

  modalClose() {
    this.modalService.dismissAll();
  }
}
