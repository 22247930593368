export class AppConstants {
  static AUTH_HEADER_KEY = 'Authorization';
  static USER_DETAILS = 'user';
  static APP_TITLE = 'Watcho Flexi Plan CMS';
  static DISH_PLAN_LIST = 'dpl';
  static PERMISSIONS = 'permissions';
  static ROUTE_LOGIN_FIRST_TIME_INDICATOR = 'rlfti';
}

export enum Status {
  ALL,
  PUBLISHED,
  UNPUBLISHED,
}

export enum PlatformType {
  ALL,
  MOBILE,
  WEB,
}

export enum FaqType {
  TEXT,
  VIDEO,
}

export enum RoleName {
  SUPER_ADMIN_ROLE,
  ADMIN_ROLE,
  USER_ROLE,
}
