<div class="container-fluid" [ngStyle]="{ 'margin-top': !availablePermissions.create ? '80px' : '' }">
  <div class="dashboard">
    <div class="row">
      <div class="col-md-12 rightSide" *ngIf="availablePermissions.create">
        <button class="btn btn-primary" (click)="registerDIYSteps()">Create New Landing Page</button>
      </div>
    </div>
    <div class="row">
      <div class="mat-elevation-z8">
        <table style="table-layout: fixed" mat-table [dataSource]="dataSource">
          <!-- Position Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="landingPageUrl">
            <th mat-header-cell *matHeaderCellDef>Landing Page URL</th>
            <td mat-cell *matCellDef="let element">
              <a
                *ngIf="element.planDetails.length > 0"
                [href]="
                  element.videoUrl !== '' && element.videoUrl !== null
                    ? campaignURL + 'campaigns/' + element.name
                    : element.name.toUpperCase().indexOf('C1-') >= 0
                    ? campaignURL + 'campaigns/' + element.name
                    : landingURL +
                      element.planDetails[0].SubscriptionPlanName.replace(' ', '-').toLowerCase() +
                      '/' +
                      element.name
                "
                target="_blank"
                >{{
                  element.videoUrl !== '' && element.videoUrl !== null
                    ? campaignURL + 'campaigns/' + element.name
                    : element.name.toUpperCase().indexOf('C1-') >= 0
                    ? campaignURL + 'campaigns/' + element.name
                    : landingURL +
                      element.planDetails[0].SubscriptionPlanName.replace(' ', '-').toLowerCase() +
                      '/' +
                      element.name
                }}</a
              >
            </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">{{ element.status }}</td>
          </ng-container>

          <!-- Action Symbol Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
              <span
                [title]="availablePermissions.update ? 'Edit Or View' : ''"
                class="material-icons"
                (click)="editDIYEntry(element.id)"
                [ngStyle]="{
                  cursor: availablePermissions.update ? 'pointer' : 'default',
                  opacity: availablePermissions.update ? '1' : '0.5'
                }"
                >edit_note</span
              >&ensp;
              <span
                class="slider-toggle-btn"
                [title]="availablePermissions.status ? (element.status !== 'PUBLISHED' ? 'Publish' : 'Unpublish') : ''"
              >
                <mat-slide-toggle
                  [disabled]="!availablePermissions.status"
                  [checked]="element.status === 'PUBLISHED' ? true : false"
                  (change)="confirmModal($event, element.id, element.status)"
                ></mat-slide-toggle> </span
              >&ensp;&ensp;&ensp;&ensp;&ensp;&nbsp;
              <span
                *ngIf="availablePermissions.delete"
                [title]="availablePermissions.delete ? 'Delete' : ''"
                [ngStyle]="{
                  cursor: availablePermissions.delete ? 'pointer' : 'default',
                  opacity: availablePermissions.delete ? '1' : '0.5'
                }"
                class="material-icons"
                (click)="confirmDeleteModal(element.id)"
                >delete_outline</span
              >
              <!-- <button class="btn btn-primary" (click)="(element.id)">Edit / View</button>&ensp;
              <button class="btn btn-primary" (click)="confirmModal(element.id, element.status)">
                {{ element.status === 'PUBLISHED' ? 'Unpublish' : 'Publish' }}
              </button>
              &ensp; <button class="btn btn-primary" (click)="confirmDeleteModal(element.id)">Delete</button>&ensp; -->
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" style="text-align: center" colspan="4">
              <i class="fas fa-cog fa-spin" style="font-size: 20px" [hidden]="!isLoading"></i>&ensp;<span
                style="font-size: 20px"
                >{{ isLoading ? 'Loading...' : 'No data found' }}</span
              >
            </td>
          </tr>
        </table>
        <!-- <ng-container *ngIf="totalElements"> -->
        <mat-paginator
          [pageSizeOptions]="[50]"
          (page)="handlePageEvent($event)"
          [length]="totalPages"
          showFirstLastButtons
          aria-label="Select page"
        >
        </mat-paginator>
        <!-- </ng-container> -->
      </div>
    </div>
  </div>
</div>

<ng-template #conformPublishUnpublish let-modal>
  <div class="modal-body confirm-modalbody">
    <p>Are you sure want to {{ status === 'PUBLISHED' ? 'publish' : 'unpublish' }} this?</p>
  </div>
  <div class="modal-footer confirm-modalfooter">
    <button class="btn btn-pink btn-cancel" (click)="modal.dismiss('Cross click')">No</button>
    <button class="btn btn-pink" (click)="publishUnpublishEntry()" [disabled]="isLoading">
      <i class="fas fa-cog fa-spin" style="font-size: 18px" [hidden]="!isLoading"></i>{{ isLoading ? '&ensp;' : 'Yes' }}
    </button>
  </div>
</ng-template>

<ng-template #confirmDelete let-modal>
  <div class="modal-body confirm-modalbody">
    <p>Are you sure want to delete this?</p>
  </div>
  <div class="modal-footer confirm-modalfooter">
    <button class="btn btn-pink btn-cancel" (click)="modal.dismiss('Cross click')">No</button>
    <button class="btn btn-pink" (click)="deleteLandingPage()" [disabled]="isLoading">
      <i class="fas fa-cog fa-spin" style="font-size: 18px" [hidden]="!isLoading"></i>{{ isLoading ? '&ensp;' : 'Yes' }}
    </button>
  </div>
</ng-template>
