<div class="container">
  <div class="card mx-auto">
    <div class="card-body">
      <!-- <div [hidden]="!isLoading" class="loader-div">
        <i class="fas fa-cog fa-spin" style="text-align: center;" [hidden]="!isLoading"></i>
      </div> -->
      <div class="row">
        <div class="row">
          <div class="col-lg-11">
            <div class="login-container--header">
              <h2>Update Landing Page</h2>
              <p>Please update landing page.</p>
            </div>
          </div>
          <div class="col-md-1">
            <div class="close-icon" (click)="modalClose()"><span class="material-icons"> highlight_off </span></div>
          </div>
        </div>
        <div class="row loader-row">
          <app-loader [isLoading]="isLoading"></app-loader>
        </div>
        <form (ngSubmit)="updateDIYEntry()" [formGroup]="updateDIYForm" novalidate *ngIf="!isLoading">
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter Name</mat-label>
                <input matInput placeholder="Enter Name" formControlName="name" required />
                <mat-error *ngIf="!updateDIYForm.controls['name'].valid || !updateDIYForm.controls['name'].untouched">
                  Name is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <!-- <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div> -->
              <!-- <div class="help-icon">
                <img [src]="toolTipList[0]" width="100%" height="100%" />
              </div> -->
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <div class="img-input-tag">
                <label class="main">Top Banner Image : </label>
                <div class="img-tag">
                  <label for="imageUrls1" class="labelBanners">
                    <div class="banner-img">
                      <img [src]="imageURL1" [hidden]="isImageUrl1" onerror="this.src='/assets/upload.png'" />
                      <div class="delete" [hidden]="isLoading">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>
                    <i class="fas fa-cog fa-spin" [hidden]="!isImageUrl1"></i>
                  </label>
                  <input type="file" hidden id="imageUrls1" accept="image/*" (change)="onFileChange1($event)" />
                  <span *ngIf="imageUrlMsg" class="err-msg">
                    {{ imageUrlErrMsg }}
                  </span>
                </div>
              </div>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[1]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <div class="img-input-tag">
                <label class="main">Bottom Banner Image : </label>
                <div class="img-tag">
                  <label for="imageUrls2" class="labelBanners">
                    <div class="banner-img">
                      <img [src]="imageURL2" [hidden]="isImageUrl2" onerror="this.src='/assets/upload.png'" />
                      <div class="delete" [hidden]="isLoading">
                        <mat-icon>edit</mat-icon>
                      </div>
                    </div>
                    <i class="fas fa-cog fa-spin" [hidden]="!isImageUrl2"></i>
                  </label>
                  <input type="file" hidden id="imageUrls2" accept="image/*" (change)="onFileChange2($event)" />
                  <span *ngIf="imageUrlMsg" class="err-msg">
                    {{ imageUrlErrMsg }}
                  </span>
                </div>
              </div>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[2]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Select Plan Name(s)</mat-label>
                <mat-select
                  placeholder="Select Plan Name(s)"
                  multiple
                  required
                  [(ngModel)]="selectedValue"
                  formControlName="planIds"
                  name="planIds"
                  [sortComparator]="sortComparator"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="searchTxt"
                      placeholderLabel="Search Plan Name(s)"
                      noEntriesFoundLabel="Not found"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let subscriptionDetail of filteredPlansMulti | async"
                    [value]="subscriptionDetail.SubscriptionPlanID"
                    (onSelectionChange)="optionedValue($event, subscriptionDetail)"
                  >
                    {{
                      subscriptionDetail.SubscriptionPlanID +
                        ' (' +
                        subscriptionDetail.SubscriptionPlanName +
                        ' - ' +
                        subscriptionDetail.SubscriptionPlanDurationName +
                        ' | Plan Category - ' +
                        subscriptionDetail.SubscriptionPlanCategory +
                        ' | Group No - ' +
                        subscriptionDetail.SubscriptionGroupNo +
                        ')'
                    }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="!updateDIYForm.controls['planIds'].valid || !updateDIYForm.controls['planIds'].untouched"
                >
                  Plan Name(s) is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[3]" width="100%" height="100%" />
              </div>
            </div>
            <div class="selected-list" *ngIf="!isLoading && selecteSubscriptionList.length > 0">
              Your Selected Plans :
              <ul>
                <li *ngFor="let subscriptionDetail of selecteSubscriptionList; let i = index">
                  {{
                    subscriptionDetail.SubscriptionPlanID +
                      ' (' +
                      subscriptionDetail.SubscriptionPlanName +
                      ' - ' +
                      subscriptionDetail.SubscriptionPlanDurationName +
                      ' | Plan Category - ' +
                      subscriptionDetail.SubscriptionPlanCategory +
                      ' | Group No - ' +
                      subscriptionDetail.SubscriptionGroupNo +
                      ')'
                  }}
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Select Plan Name(s)</mat-label>
                <mat-select
                  formControlName="planIds"
                  [(ngModel)]="selectedValue"
                  name="planIds"
                  multiple
                  (selectionChange)="selectItem($event)"
                  [sortComparator]="sortComparator"
                >
                  <div class="search-input">
                    <input
                      [(ngModel)]="searchTxt"
                      matInput
                      placeholder="Search Plan Name(s)"
                      [ngModelOptions]="{ standalone: true }"
                      class="planIds-name"
                    />
                  </div>
                  <mat-option
                    *ngFor="let subscriptionDetail of subscriptionList | search: searchTxt"
                    [value]="subscriptionDetail.SubscriptionPlanID"
                  >
                    {{
                      subscriptionDetail.SubscriptionPlanID +
                        ' (' +
                        subscriptionDetail.SubscriptionPlanName +
                        ' - ' +
                        subscriptionDetail.SubscriptionPlanDurationName +
                        ' | Plan Category - ' +
                        subscriptionDetail.SubscriptionPlanCategory +
                        ')'
                    }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="!updateDIYForm.controls['planIds'].valid || !updateDIYForm.controls['planIds'].untouched"
                >
                  Plan Name(s) is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[3]" width="100%" height="100%" />
              </div>
            </div>
            <div class="selected-list" *ngIf="!isLoading && selecteSubscriptionList.length > 0">
              Your Selected Plans :
              <ul>
                <li *ngFor="let subscriptionDetail of selecteSubscriptionList; let i = index">
                  {{
                    subscriptionDetail.SubscriptionPlanID +
                      ' (' +
                      subscriptionDetail.SubscriptionPlanName +
                      ' - ' +
                      subscriptionDetail.SubscriptionPlanDurationName +
                      ' | Plan Category - ' +
                      subscriptionDetail.SubscriptionPlanCategory +
                      ')'
                  }}
                </li>
              </ul>
            </div>
          </div> -->
          <div class="row coupon-slider-toggle-btn">
            <mat-slide-toggle [(ngModel)]="isChecked" [ngModelOptions]="{ standalone: true }"
              >Slide here for the Add Multi Coupons</mat-slide-toggle
            >
          </div>
          <div class="row" *ngIf="!isChecked">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter a Coupon</mat-label>
                <input matInput placeholder="Enter a Coupon" formControlName="coupon" />
                <!-- <mat-error
                  *ngIf="!updateDIYForm.controls['coupon'].valid || !updateDIYForm.controls['coupon'].untouched"
                >
                  Coupon is required</mat-error
                > -->
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[4]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isChecked">
            <div class="mat-input-type multi-tags">
              <ngx-mat-tag-input
                appearance="fill"
                #aa
                (change)="change($event)"
                [selectedTags]="couponEvents"
                appearance="fill"
                placeholder="Add Multi Coupons"
              ></ngx-mat-tag-input>
              <small class="form-text text-muted"
                ><b>Note : </b>Enter Separate coupons with a comma or enter key & Enter <b>NAC</b>(Not Applicable
                Coupon) for empty coupon.</small
              >
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[4]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter a Coupon Validity</mat-label>
                <input matInput placeholder="Enter a Coupon Validity" formControlName="couponValidity" />
                <!-- <mat-error
                  *ngIf="!updateDIYForm.controls['couponValidity'].valid || !updateDIYForm.controls['couponValidity'].untouched"
                >
                  couponValidity is required</mat-error
                > -->
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[4]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter a Campaign Offer Message</mat-label>
                <input matInput placeholder="Enter a Campaign Offer Message" formControlName="campaignMessage" />
                <!-- <mat-error
                  *ngIf="!updateDIYForm.controls['campaignMessage'].valid || !updateDIYForm.controls['campaignMessage'].untouched"
                >
                  Campaign Offer Message is required</mat-error
                > -->
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[4]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter a Sub Campaign Offer Message</mat-label>
                <input matInput placeholder="Enter a Sub Campaign Offer Message" formControlName="subCampaignMessage" />
                <!-- <mat-error
                  *ngIf="!updateDIYForm.controls['subCampaignMessage'].valid || !updateDIYForm.controls['subCampaignMessage'].untouched"
                >
                  Sub Campaign Offer Message is required</mat-error
                > -->
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[4]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Pick Color</mat-label>
                <input
                  matInput
                  (colorPickerChange)="selectedColor = $event"
                  [style.background]="selectedColor"
                  [cpOKButton]="pickColorButton"
                  cpOKButtonText="OK"
                  cpOKButtonClass="mat-raised-button mat-primary"
                  [colorPicker]="selectedColor"
                  [value]="selectedColor"
                  readonly
                />
                <mat-icon matSuffix>color_lens</mat-icon>
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[5]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row" *ngIf="imageURL1.length === 0 ? (imageURL2.length === 0 ? true : false) : false">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter a Video Url</mat-label>
                <input matInput placeholder="Enter a Video Url" formControlName="videoUrl" />
                <!-- <mat-error
                  *ngIf="!updateDIYForm.controls['videoUrl'].valid || !updateDIYForm.controls['videoUrl'].untouched"
                >
                  Video Url is required</mat-error
                > -->
              </mat-form-field>
            </div>
            <span class="notes"
              ><i
                >Note : Video Url Must Be Embed Video from Youtube Channel.
                <a href="https://support.google.com/youtube/answer/171780?hl=en" target="_blank">Click Here</a> for More
                Info</i
              ></span
            >
          </div>
          <div class="row button-align">
            <button class="btn btn-primary w-100" type="submit" [disabled]="isLoading">
              <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
              <span translate>Update Landing Page</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
