export interface CreateCustomPackCommand {
  title: string;
  subTitle: string;
  saveUptoText: string;
  displayOrder: number;
  buttonText: string;
  planIds: number[];
  type: string;
}

export interface UpdateCustomPackCommand {
  title: string;
  subTitle: string;
  saveUptoText: string;
  displayOrder: number;
  buttonText: string;
  planIds: number[];
  id: number;
  customPackStatus: string;
  type: string;
}

export interface CreateCustomFlexiPackCommand {
  flexiLeftText: string;
  flexiRightText1: string;
  flexiRightText2: string;
  saveUptoText: string;
  displayOrder: number;
  buttonText: string;
  planIds: number[];
  type: string;
  colourCode: string;
}

export interface UpdateCustomFlexiPackCommand {
  flexiLeftText: string;
  flexiRightText1: string;
  flexiRightText2: string;
  saveUptoText: string;
  displayOrder: number;
  buttonText: string;
  planIds: number[];
  id: number;
  customPackStatus: string;
  type: string;
  colourCode: string;
}

export interface PeriodicElement {
  buttonText: string;
  customPackStatus: string;
  displayOrder: number;
  id: number;
  planIds: number[];
  saveUptoText: string;
  subTitle: string;
  title: string;
  type: string;
}

export interface PeriodicFlexiElement {
  buttonText: string;
  customPackStatus: string;
  displayOrder: number;
  id: number;
  planIds: number[];
  saveUptoText: string;
  flexiRightText2: string;
  flexiRightText1: string;
  flexiLeftText: string;
  type: string;
}

export enum Type {
  CUSTOM,
  FLEXI,
  RPC,
}

export enum SubscriptionPlanType {
  WATCHOEXCLUSIVES,
  WATCHOONE,
  FLEXI,
}
