import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { I18nModule } from '@app/i18n';
import { AuthModule } from '@app/auth';
import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SharedModule } from '@app/@shared';
import { SideNavComponent } from './side-nav/side-nav.component';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { LoginMessageService } from '@app/@shared/services/login-message.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    AuthModule,
    I18nModule,
    RouterModule,
    MatSidenavModule,
    SharedModule,
    MatInputModule,
    MatIconModule,
  ],
  declarations: [HeaderComponent, ShellComponent, SideNavComponent],
  providers: [LoginMessageService],
})
export class ShellModule {}
