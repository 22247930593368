import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchTxt: string): any[] {
    if (!items || !items.length) return items;
    if (!searchTxt || !searchTxt.length) return items;
    return items.filter((item) => {
      return !isNaN(Number(searchTxt))
        ? item.SubscriptionPlanID.toString().indexOf(searchTxt) > -1
        : item.SubscriptionPlanName
        ? item.SubscriptionPlanName.toString().toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
        : item.landingPageName
        ? item.landingPageName.toString().toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
        : null;
    });
  }
}
