//All Permisson Names
export enum PermissionName {
  'LP_CREATE',
  'BANNERS_RPC_UPDATE',
  'CMS_USERS_VIEW',
  'WINBACK_URLS_CREATE',
  'CMS_USERS_LIST',
  'LP_FAQ_CHANGE_STATUS',
  'MANAGE_LP_CREATE',
  'WINBACK_URLS_VIEW',
  'LP8_CHANGE_STATUS',
  'CUSTOM_PACKS_FIXED_CHANGE_STATUS',
  'LP_TYPE_CREATE',
  'CMS_USERS_ACTIVATE',
  'CUSTOM_PACKS_FLEXI_CREATE',
  'LP_GET_BY_ID',
  'CMS_USERS_USERS_RESET_PASSWORD',
  'LP7_LIST',
  'LP6_CREATE',
  'LP_FAQ_UPDATE',
  'MYOP_BANNERS_UPDATE',
  'MANAGE_LP_GET_BY_ID',
  'BANNERS_FIXED_UPDATE',
  'GET_CMS_USERS',
  'CUSTOM_PACKS_FIXED_CREATE',
  'LP_CHANGE_STATUS',
  'CUSTOM_PACKS_FLEXI_UPDATE',
  'BANNERS_FLEXI_CHANGE_STATUS',
  'CUSTOM_PACK_DISH_SUBSCRIPTION_LIST',
  'LP8_CREATE',
  'LP8_GET_BY_ID',
  'MYOP_BANNERS_CREATE',
  'LP6_DELETE',
  'LP7_UPDATE',
  'LP8_UPDATE',
  'BANNERS_RPC_CHANGE_STATUS',
  'LP_LIST',
  'LP7_GET_BY_ID',
  'MANAGE_LP_UPDATE',
  'LP6_UPDATE',
  'LP6_LIST',
  'BANNERS_FLEXI_UPDATE',
  'CMS_USERS_UPDATE',
  'BANNERS_FLEXI_DELETE',
  'CMS_USERS_UPDATE_PERMISSIONS',
  'LP_FAQ_VIEW',
  'MYOP_BANNERS_GET_BY_ID',
  'LP_TYPE_LIST',
  'LP_FAQ_DELETE',
  'CUSTOM_PACKS_FLEXI_CHANGE_STATUS',
  'CHANGE_PASSWORD',
  'CUSTOM_PACK_SYNC',
  'LP7_DELETE',
  'LP8_DELETE',
  'BANNERS_RPC_CREATE',
  'CUSTOM_PACKS_CHANGE_STATUS',
  'CMS_USERS_DELETE',
  'MYOP_BANNERS_VIEW',
  'WINBACK_URLS_UPDATE',
  'LP_TYPE_DELETE',
  'MYOP_BANNERS_LIST',
  'BANNERS_FIXED_CHANGE_STATUS',
  'LP_UPDATE',
  'LP_FAQ_LIST_BY_STATUS',
  'CUSTOM_PACKS_FIXED_UPDATE',
  'BANNERS_FIXED_CREATE',
  'MANAGE_LP_DELETE',
  'LP6_CHANGE_STATUS',
  'MYOP_BANNERS_CHANGE_STATUS',
  'LP_DELETE',
  'CUSTOM_PACKS_GET_BY_ID',
  'LP_FAQ_CREATE',
  'LP6_GET_BY_ID',
  'CUSTOM_PACKS_FIXED_DELETE',
  'WINBACK_URLS_DELETE',
  'BANNERS_LIST_ALL',
  'LP_FAQ_GET_BY_ID',
  'WINBACK_URLS_LIST',
  'LP8_LIST',
  'LP7_CREATE',
  'LP7_CHANGE_STATUS',
  'MYOP_BANNERS_DELETE',
  'MANAGE_LP_LIST_ALL',
  'CUSTOM_PACKS_FLEXI_DELETE',
  'LP_FAQ_LIST',
  'BANNERS_FLEXI_CREATE',
  'BANNERS_RPC_GET_TYPE',
  'CUSTOM_PACKS_LIST_ALL',
  'CMS_USERS_CREATE',
  'GET_All_UPGRADE_PLAN_PAGE',
  'CREATE_UPGRADE_PLAN_PAGE',
  'UPDATE_UPGRADE_PLAN_PAGE',
  'GET_UPGRADE_PLAN_PAGE',
  'DELETE_UPGRADE_PLAN_PAGE',
  'CHANGE_STATUS_UPGRADE_PLAN_PAGE',
  'UPGRADE_BANNER_LIST_ALL',
  'UPGRADE_BANNER_CREATE',
  'UPGRADE_BANNER_UPDATE',
  'UPGRADE_BANNER_CHANGE_STATUS',
  'UPGRADE_BANNER_DELETE_BY_ID',
  'UPGRADE_BANNER_GET_BY_ID',
  'UPGRADE_BANNER_UPDATE_DEFAULT_STATUS',
  'LP9_LIST',
  'LP9_DELETE',
  'LP9_UPDATE',
  'LP9_CHANGE_STATUS',
  'LP9_CREATE',
  'LP9_GET_BY_ID',
  'UTILITY_KNOW_MORE_GET_ENTRY',
  'UTILITY_KNOW_MORE_CHANGE_ENTRY_STATUS',
  'UTILITY_KNOW_MORE_DELETE_ENTRY',
  'UTILITY_KNOW_MORE_MAKE_ENTRY',
}

//used by auth gaurd
export enum PagePermission {
  CustomPacks = 'CUSTOM_PACKS_LIST_ALL',
  Banners = 'BANNERS_LIST_ALL',
  MakeYourOwnPack = 'MYOP_BANNERS_LIST',
  LandingPageList = 'LP_LIST',
  ManageLandingPage = 'MANAGE_LP_LIST_ALL',
  LandingPage6List = 'LP6_LIST',
  LandingPage7List = 'LP7_LIST',
  LandingPage8List = 'LP8_LIST',
  LandingPageFaq = 'LP_FAQ_LIST',
  WinbackUrls = 'WINBACK_URLS_LIST',
  UpgragePages = 'GET_All_UPGRADE_PLAN_PAGE',
  KnowMoreURLs = 'UTILITY_KNOW_MORE_GET_ENTRY',
  CMSUsers = 'CMS_USERS_LIST',
  LandingPage9List = 'LP9_LIST',
}

//used by pages menu filter
export enum Permission {
  CUSTOM_PACKS_LIST_ALL = 'Custom Packs',
  BANNERS_LIST_ALL = 'Banners',
  // MYOP_BANNERS_LIST = 'Make Your Own Pack Banners',
  LP_LIST = 'Landing Page',
  MANAGE_LP_LIST_ALL = 'Manage Landing Page',
  LP6_LIST = 'Advance Landing Page',
  LP7_LIST = 'Offers-2 Landing Page',
  LP8_LIST = 'Flexi-1 Landing Page',
  LP_FAQ_LIST = 'Landing Page FAQ(s)',
  WINBACK_URLS_LIST = 'Winback URL(s)',
  GET_All_UPGRADE_PLAN_PAGE = 'Upgrade Plan Page(s)',
  LP9_LIST = 'BOGO Landing Page(s)',
  UTILITY_KNOW_MORE_GET_ENTRY = 'Know More URL(s)',
  CMS_USERS_LIST = 'CMS Users',
}

export const PageRedirectWithPermissionName: any = {
  CUSTOM_PACKS_LIST_ALL: '/home',
  BANNERS_LIST_ALL: '/banners',
  // MYOP_BANNERS_LIST: '/make-your-own-pack',
  LP_LIST: '/landing-page',
  MANAGE_LP_LIST_ALL: '/manage-campaigns',
  LP6_LIST: '/landing-page-six',
  LP7_LIST: '/landing-page-seven',
  LP8_LIST: '/landing-page-eight',
  LP_FAQ_LIST: '/campaign-faq',
  WINBACK_URLS_LIST: '/winback-generate-url',
  GET_All_UPGRADE_PLAN_PAGE: '/upgrade-pages',
  LP9_LIST: '/landing-page-nine',
  UTILITY_KNOW_MORE_GET_ENTRY: '/know-more-urls',
  CMS_USERS_LIST: '/accounts',
};

//used by register user by role and edit user
export class AvailablePermissionsList {
  Custom_Packs_Management = {
    read: [PermissionName[PermissionName.CUSTOM_PACKS_LIST_ALL], PermissionName[PermissionName.CUSTOM_PACKS_GET_BY_ID]],
    write: [
      PermissionName[PermissionName.CUSTOM_PACKS_FIXED_CREATE],
      PermissionName[PermissionName.CUSTOM_PACKS_FIXED_UPDATE],
      PermissionName[PermissionName.CUSTOM_PACKS_FIXED_CHANGE_STATUS],
      PermissionName[PermissionName.CUSTOM_PACKS_FLEXI_CREATE],
      PermissionName[PermissionName.CUSTOM_PACKS_FLEXI_UPDATE],
      PermissionName[PermissionName.CUSTOM_PACKS_FLEXI_CHANGE_STATUS],
    ],
    delete: [
      PermissionName[PermissionName.CUSTOM_PACKS_FIXED_DELETE],
      PermissionName[PermissionName.CUSTOM_PACKS_FLEXI_DELETE],
    ],
  };

  Banners_Management = {
    read: [PermissionName[PermissionName.BANNERS_LIST_ALL], PermissionName[PermissionName.BANNERS_RPC_GET_TYPE]],
    write: [
      PermissionName[PermissionName.BANNERS_FIXED_CREATE],
      PermissionName[PermissionName.BANNERS_FIXED_UPDATE],
      PermissionName[PermissionName.BANNERS_FIXED_CHANGE_STATUS],
      PermissionName[PermissionName.BANNERS_FLEXI_CREATE],
      PermissionName[PermissionName.BANNERS_FLEXI_UPDATE],
      PermissionName[PermissionName.BANNERS_FLEXI_CHANGE_STATUS],
      PermissionName[PermissionName.BANNERS_RPC_CREATE],
      PermissionName[PermissionName.BANNERS_RPC_UPDATE],
      PermissionName[PermissionName.BANNERS_RPC_CHANGE_STATUS],
    ],
    delete: [
      // PermissionName[PermissionName.BANNERS_FIXED_DELETE],
      PermissionName[PermissionName.BANNERS_FLEXI_DELETE],
      // PermissionName[PermissionName.BANNERS_RPC_DELETE],
    ],
  };

  // Make_Your_Own_Pack_Management = {
  //   read: [PermissionName[PermissionName.MYOP_BANNERS_LIST], PermissionName[PermissionName.MYOP_BANNERS_GET_BY_ID]],
  //   write: [
  //     PermissionName[PermissionName.MYOP_BANNERS_CREATE],
  //     PermissionName[PermissionName.MYOP_BANNERS_UPDATE],
  //     PermissionName[PermissionName.MYOP_BANNERS_CHANGE_STATUS],
  //   ],
  //   delete: [PermissionName[PermissionName.MYOP_BANNERS_DELETE]],
  // };

  Landing_Page_Management = {
    read: [
      PermissionName[PermissionName.LP_LIST],
      PermissionName[PermissionName.LP_GET_BY_ID],
      PermissionName[PermissionName.MANAGE_LP_LIST_ALL],
      PermissionName[PermissionName.MANAGE_LP_GET_BY_ID],
    ],
    write: [
      PermissionName[PermissionName.LP_CREATE],
      PermissionName[PermissionName.LP_UPDATE],
      PermissionName[PermissionName.LP_CHANGE_STATUS],
      PermissionName[PermissionName.MANAGE_LP_CREATE],
      PermissionName[PermissionName.MANAGE_LP_UPDATE],
    ],
    delete: [PermissionName[PermissionName.LP_DELETE]],
  };

  // Manage_Landing_Page_Management = {
  //   read: [PermissionName[PermissionName.MANAGE_LP_LIST_ALL], PermissionName[PermissionName.MANAGE_LP_GET_BY_ID]],
  //   write: [PermissionName[PermissionName.MANAGE_LP_CREATE], PermissionName[PermissionName.MANAGE_LP_UPDATE]],
  //   delete: [PermissionName[PermissionName.MANAGE_LP_DELETE]],
  // };

  Advance_Landing_Page_Management = {
    read: [PermissionName[PermissionName.LP6_LIST], PermissionName[PermissionName.LP6_GET_BY_ID]],
    write: [
      PermissionName[PermissionName.LP6_CREATE],
      PermissionName[PermissionName.LP6_UPDATE],
      PermissionName[PermissionName.LP6_CHANGE_STATUS],
    ],
    delete: [PermissionName[PermissionName.LP6_DELETE]],
  };

  Offer_2_Landing_Page_Management = {
    read: [PermissionName[PermissionName.LP7_LIST], PermissionName[PermissionName.LP7_GET_BY_ID]],
    write: [
      PermissionName[PermissionName.LP7_CREATE],
      PermissionName[PermissionName.LP7_UPDATE],
      PermissionName[PermissionName.LP7_CHANGE_STATUS],
    ],
    delete: [PermissionName[PermissionName.LP7_DELETE]],
  };

  Flexi_1_Landing_Page_Management = {
    read: [PermissionName[PermissionName.LP8_LIST], PermissionName[PermissionName.LP8_GET_BY_ID]],
    write: [
      PermissionName[PermissionName.LP8_CREATE],
      PermissionName[PermissionName.LP8_UPDATE],
      PermissionName[PermissionName.LP8_CHANGE_STATUS],
    ],
    delete: [PermissionName[PermissionName.LP8_DELETE]],
  };

  BOGO_Landing_Page_Management = {
    read: [PermissionName[PermissionName.LP9_LIST], PermissionName[PermissionName.LP9_GET_BY_ID]],
    write: [
      PermissionName[PermissionName.LP9_CREATE],
      PermissionName[PermissionName.LP9_UPDATE],
      PermissionName[PermissionName.LP9_CHANGE_STATUS],
    ],
    delete: [PermissionName[PermissionName.LP9_DELETE]],
  };

  Landing_Page_FAQs = {
    read: [
      PermissionName[PermissionName.LP_FAQ_LIST],
      PermissionName[PermissionName.LP_FAQ_GET_BY_ID],
      PermissionName[PermissionName.LP_FAQ_LIST_BY_STATUS],
    ],
    write: [
      PermissionName[PermissionName.LP_FAQ_CREATE],
      PermissionName[PermissionName.LP_FAQ_UPDATE],
      PermissionName[PermissionName.LP_FAQ_CHANGE_STATUS],
    ],
    delete: [PermissionName[PermissionName.LP_FAQ_DELETE]],
  };

  Winback_Urls_Management = {
    read: [PermissionName[PermissionName.WINBACK_URLS_LIST], PermissionName[PermissionName.WINBACK_URLS_VIEW]],
    write: [PermissionName[PermissionName.WINBACK_URLS_CREATE], PermissionName[PermissionName.WINBACK_URLS_UPDATE]],
    delete: [PermissionName[PermissionName.WINBACK_URLS_DELETE]],
  };

  Upgrade_Pages_Management = {
    read: [
      PermissionName[PermissionName.GET_All_UPGRADE_PLAN_PAGE],
      PermissionName[PermissionName.GET_UPGRADE_PLAN_PAGE],
      PermissionName[PermissionName.UPGRADE_BANNER_LIST_ALL],
      PermissionName[PermissionName.UPGRADE_BANNER_GET_BY_ID],
    ],
    write: [
      PermissionName[PermissionName.CREATE_UPGRADE_PLAN_PAGE],
      PermissionName[PermissionName.UPDATE_UPGRADE_PLAN_PAGE],
      PermissionName[PermissionName.CHANGE_STATUS_UPGRADE_PLAN_PAGE],
      PermissionName[PermissionName.UPGRADE_BANNER_CREATE],
      PermissionName[PermissionName.UPGRADE_BANNER_UPDATE],
      PermissionName[PermissionName.UPGRADE_BANNER_CHANGE_STATUS],
      PermissionName[PermissionName.UPGRADE_BANNER_UPDATE_DEFAULT_STATUS],
    ],
    delete: [
      PermissionName[PermissionName.DELETE_UPGRADE_PLAN_PAGE],
      PermissionName[PermissionName.UPGRADE_BANNER_DELETE_BY_ID],
    ],
  };

  Know_More_URLs_Management = {
    read: [PermissionName[PermissionName.UTILITY_KNOW_MORE_GET_ENTRY]],
    write: [
      PermissionName[PermissionName.UTILITY_KNOW_MORE_MAKE_ENTRY],
      PermissionName[PermissionName.UTILITY_KNOW_MORE_CHANGE_ENTRY_STATUS],
    ],
    delete: [PermissionName[PermissionName.UTILITY_KNOW_MORE_DELETE_ENTRY]],
  };

  CMS_Users_Management = {
    read: [
      PermissionName[PermissionName.CMS_USERS_LIST],
      PermissionName[PermissionName.CMS_USERS_VIEW],
      PermissionName[PermissionName.GET_CMS_USERS],
    ],
    write: [
      PermissionName[PermissionName.CMS_USERS_CREATE],
      PermissionName[PermissionName.CMS_USERS_UPDATE],
      PermissionName[PermissionName.CMS_USERS_ACTIVATE],
    ],
    delete: [
      PermissionName[PermissionName.CMS_USERS_USERS_RESET_PASSWORD],
      PermissionName[PermissionName.CMS_USERS_UPDATE_PERMISSIONS],
    ],
  };
}
