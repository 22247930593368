import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { QuoteService } from './quote.service';
import { AuthenticationService } from '@app/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionCheckService } from '@app/@shared/services/permission-check.service';
import { PagePermission, Permission } from '@app/@shared/models/permissions.enum';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  quote: string | undefined;
  isLoading = false;
  writeButton: boolean = false;

  constructor(
    private quoteService: QuoteService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private permissionCheckService: PermissionCheckService
  ) {
    // if (
    //   this.authenticationService.getUserDetails().toLowerCase() ===
    //   this.authenticationService.getAllowMenuEmailList().filter((data) => {
    //     return data === this.authenticationService.getUserDetails().toLowerCase();
    //   })[0]
    // ) {
    //   this.router.navigate([this.route.snapshot.queryParams['redirect'] || '/winback-generate-url'], {
    //     replaceUrl: true,
    //   });
    // } else {
    //   this.router.navigate([this.route.snapshot.queryParams['redirect'] || '/'], { replaceUrl: true });
    // }
  }

  ngOnInit() {
    this.isLoading = true;
    this.checkPermissions();
    // this.quoteService
    //   .getRandomQuote({ category: 'dev' })
    //   .pipe(
    //     finalize(() => {
    //       this.isLoading = false;
    //     })
    //   )
    //   .subscribe((quote: string) => {
    //     this.quote = quote;
    //   });
  }

  checkPermissions() {
    this.writeButton = !this.checkUserPermission(PagePermission.CustomPacks);
  }

  checkUserPermission(permission: any) {
    const result: boolean = this.permissionCheckService.hasPermission(permission);
    // console.log(`Permission ${permission}: ${result}`);
    return result;
  }
}
