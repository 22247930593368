import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

declare var AWS: any;

@Injectable({
  providedIn: 'root',
})
export class AwsService {
  constructor() {}

  public loadScript() {
    const node1 = document.createElement('script');
    node1.src = 'https://sdk.amazonaws.com/js/aws-sdk-2.224.1.min.js';

    document.getElementsByTagName('head')[0].appendChild(node1);
  }

  loadAWSConfig() {
    AWS.config.update({
      region: 'ap-south-1',
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'ap-south-1:32c8a821-547b-4f47-aebc-b90b48456e99',
      }),
    });
    this.initS3();
  }

  initS3() {
    new AWS.S3({
      apiVersion: '2006-03-01',
      params: { Bucket: 'plans-offers' },
    });
  }

  getCloudUrl(): string {
    return environment.CLOUD_URL;
  }

  uploadPhoto(selectedFile: any, targetFolder: any) {
    return new Promise((res, rej) => {
      var file = selectedFile;
      var fileName = file.name;
      var albumPhotosKey = encodeURIComponent(targetFolder) + '/';
      var uniqueIdentifier = new Date().getTime();
      var photoKey = albumPhotosKey + uniqueIdentifier + fileName;

      // Use S3 ManagedUpload class as it supports multipart uploads
      var upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: 'plans-offers',
          Key: photoKey,
          Body: file,
          ContentType: 'image/png, image/jpeg, image/jpg',
        },
      });

      var promise = upload.on('httpUploadProgress', (evt: any) => {}).promise();

      promise.then(
        (data: any) => {
          res(data);
        },
        (err: any) => {
          rej();
        }
      );
    });
  }
}
