import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { ApisService } from '@app/home/components/service/apis.service';

@Pipe({
  name: 'trim',
})
@Injectable()
export class StringTrimPipe implements PipeTransform {
  constructor(private apisService: ApisService) {}

  public transform(str: string, length?: number) {
    return this.apisService.stringTrim(str, length);
  }
}
