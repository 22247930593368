import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { environment } from '@env/environment';
import { Logger, UntilDestroy } from '@shared';
import { AuthenticationService } from './authentication.service';
import { AppConstants } from './typings/common-constants';
import { isPlatformBrowser } from '@angular/common';
import { ApisService } from '@app/home/components/service/apis.service';
import { PageRedirectWithPermissionName, PermissionName } from '@app/@shared/models/permissions.enum';

const log = new Logger('Login');
declare var $: any;

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  forgotForm!: FormGroup;
  isLoading = false;
  foundErrorLogin: boolean = false;
  foundForgotMessage: boolean = false;
  hide: boolean = true;
  isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private apisService: ApisService
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.createForm();
  }

  ngOnInit() {
    if (this.isBrowser) {
      localStorage.clear();
    }
  }

  login() {
    if (this.isBrowser) {
      this.isLoading = true;
      this.foundForgotMessage = false;
      if (this.loginForm.valid) {
        this.authenticationService.login(this.loginForm.value).subscribe(
          (res) => {
            if (res.status) {
              localStorage.setItem(AppConstants.ROUTE_LOGIN_FIRST_TIME_INDICATOR, 'true');
              localStorage.setItem(AppConstants.AUTH_HEADER_KEY, res.data.token);
              localStorage.setItem(AppConstants.USER_DETAILS, res.data.name);
              localStorage.setItem(AppConstants.PERMISSIONS, JSON.stringify(res.data.permissions));
              if (res.data.permissions && res.data.permissions.length > 0) {
                let onceTimeCall: boolean = false;
                if (res.data.permissions.includes(PermissionName[PermissionName.CUSTOM_PACKS_LIST_ALL])) {
                  this.router.navigate([this.route.snapshot.queryParams['redirect'] || '/'], { replaceUrl: true });
                } else {
                  res.data.permissions.forEach((element: string) => {
                    if (element.indexOf('LIST') >= 0 && !onceTimeCall) {
                      onceTimeCall = true;
                      // console.log(element);
                      this.router.navigate([PageRedirectWithPermissionName[element]], { replaceUrl: true });
                    }
                  });
                }
              }
              if (!localStorage.getItem(AppConstants.DISH_PLAN_LIST)) {
                this.getAvailableSubscriptionPlanDetails();
              }
            } else {
              this.foundErrorLogin = true;
            }
            this.isLoading = false;
          },
          (error) => {
            this.foundErrorLogin = true;
            this.isLoading = false;
          }
        );
      } else {
        this.foundErrorLogin = false;
        this.isLoading = false;
      }
    }
  }

  forgotPasswordToggle() {
    this.foundErrorLogin = false;
    this.foundForgotMessage = true;
    setTimeout(() => {
      this.foundForgotMessage = false;
    }, 4000);
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      remember: true,
    });
  }

  getAvailableSubscriptionPlanDetails() {
    this.apisService.getAvailableSubscriptionPlanDetails().subscribe(
      (res: any) => {
        if (res.resultCode === 0) {
          if (res.result && res.result.length > 0) {
            localStorage.setItem(AppConstants.DISH_PLAN_LIST, JSON.stringify(res.result));
          }
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }
}
