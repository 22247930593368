<div class="login-container bg-light">
  <div class="login-box">
    <div class="container">
      <div class="card col-md-9 mx-auto">
        <div class="card-body">
          <h4 class="card-title text-center mt-2">
            <div class="org-logo">
              <img src="assets/watcho-logo-small.png" alt="" />
            </div>
          </h4>
          <div class="row">
            <div class="col-md-6">
              <div class="login-container--header">
                <h2>Welcome!</h2>
                <p>Please sign in into Watcho flexi cms with your existing login.</p>
              </div>
              <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
                <div *ngIf="!foundForgotMessage && foundErrorLogin" class="alert alert-danger" translate>
                  Username or password incorrect.
                </div>
                <div class="m-3">
                  <div *ngIf="foundForgotMessage && !foundErrorLogin" class="alert alert-warning" translate>
                    <span>To reset the password please contact your administrator.</span>
                  </div>
                  <div>
                    <mat-form-field appearance="fill">
                      <mat-label>Enter your email</mat-label>
                      <input
                        matInput
                        placeholder="Enter your email"
                        formControlName="email"
                        required
                        type="email"
                        email="true"
                      />
                      <mat-error *ngIf="!loginForm.controls['email'].valid || !loginForm.controls['email'].untouched"
                        >Email is required</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="mt-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Enter your Password</mat-label>
                      <input
                        matInput
                        placeholder="Enter your Password"
                        formControlName="password"
                        [type]="hide ? 'password' : 'text'"
                        required
                      />
                      <div
                        mat-icon-button
                        matSuffix
                        (click)="hide = !hide"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide"
                      >
                        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                      </div>
                      <mat-error
                        *ngIf="!loginForm.controls['password'].valid || !loginForm.controls['password'].untouched"
                        >Password is required</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="forgot-password mt-2" (click)="forgotPasswordToggle()">Forgot Password?</div>
                </div>
                <div class="m-3">
                  <button class="btn btn-primary w-100" type="submit" [disabled]="isLoading">
                    <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
                    <span translate>Sign In</span>
                  </button>
                </div>
              </form>
            </div>
            <div class="login-container__right col-md-6">
              <img width="45%" src="assets/sidevideo.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
