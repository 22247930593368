<nav class="main-navbar">
  <div class="logo"></div>
  <div class="main-navbar__content">
    <ul class="main-navbar__content__wrapper">
      <ng-container *ngFor="let item of navItems">
        <li class="main-navbar__content__wrapper--item mb-2">
          <div
            class="nav-item"
            [ngClass]="{ active: currentRoute === item.redirectTo }"
            [routerLink]="[item.redirectTo]"
            routerLinkActive="active"
          >
            <span class="material-icons nav-icon">
              {{ item.icon }}
            </span>
            <div class="nav-title">{{ item.title }}</div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</nav>
