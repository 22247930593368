export interface PeriodicElement {
  name: string;
  topBanner: string;
  bottomBanner: string;
  planIds: string;
  coupon: string;
  planDetails: string;
  status: string;
  id: number;
  videoUrl: string;
}

export interface CreateLandingPageCommand {
  name: string;
  topBanner: string;
  bottomBanner: string;
  coupon: string;
  planIds: number[];
  planIdDTOList: PlanIdDTOList[];
  colour: string;
  campaignMessage?: string;
  subCampaignMessage?: string;
  multipleCouponMap: any[];
  couponValidity: string;
  videoUrl: string | null;
}

export interface UpdateLandingPageCommand {
  topBanner: string;
  bottomBanner: string;
  coupon: string;
  planIds: number[];
  planIdDTOList: PlanIdDTOList[];
  colour: string;
  id: number;
  campaignMessage?: string;
  subCampaignMessage?: string;
  multipleCouponMap: any[];
  couponValidity: string;
  videoUrl: string | null;
}

export enum Type {
  CUSTOM,
  FLEXI,
}

export enum SubscriptionPlanType {
  WATCHOEXCLUSIVES,
  WATCHOONE,
  FLEXI,
}

export interface MultiCoupon {
  id?: number;
  planId: number;
  coupon: string;
}

export interface MultiCouponList {
  multiCoupon: MultiCoupon[];
}

export interface PlanIdDTOList {
  orders: number;
  planId: number;
}
