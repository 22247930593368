import { Component, OnInit, Output, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { AuthenticationService } from '@app/auth';
import { AppConstants } from '@app/auth/typings/common-constants';
import { Permission } from '@app/@shared/models/permissions.enum';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @Output() isClickedMenu = new EventEmitter<boolean>(true);
  isBrowser!: boolean;
  public menuItems: any[] = [
    {
      title: 'Custom Packs',
      redirectTo: '/home',
      icon: 'home',
    },
    {
      title: 'Banners',
      redirectTo: '/banners',
      icon: 'ballot',
    },
    // {
    //   title: 'Make Your Own Pack Banners',
    //   redirectTo: '/make-your-own-pack',
    //   icon: 'business_center',
    // },
    {
      title: 'Landing Page',
      redirectTo: '/landing-page',
      icon: 'dvr',
    },
    {
      title: 'Manage Landing Page',
      redirectTo: '/manage-campaigns',
      icon: 'settings_brightness',
    },
    {
      title: 'Advance Landing Page',
      redirectTo: '/landing-page-six',
      icon: 'calendar_view_day',
    },
    {
      title: 'Offers-2 Landing Page',
      redirectTo: '/landing-page-seven',
      icon: 'newspaper',
    },
    {
      title: 'Flexi-1 Landing Page',
      redirectTo: '/landing-page-eight',
      icon: 'class',
    },
    {
      title: 'Landing Page FAQ(s)',
      redirectTo: '/campaign-faq',
      icon: 'dataset',
    },
    {
      title: 'Winback URL(s)',
      redirectTo: '/winback-generate-url',
      icon: 'blur_linear',
    },
    {
      title: 'Upgrade Plan Page(s)',
      redirectTo: '/upgrade-pages',
      icon: 'move_up',
    },
    {
      title: 'BOGO Landing Page(s)',
      redirectTo: '/landing-page-nine',
      icon: 'landscape',
    },
    {
      title: 'Know More URL(s)',
      redirectTo: 'know-more-urls',
      icon: 'move_up',
    },
    {
      title: 'CMS Users',
      redirectTo: '/accounts',
      icon: 'supervisor_account',
    },
  ];
  public currentRoute: string = '';
  roleName!: string;
  permissionMapping = Permission;
  navItems: any[] = this.filterMenuItemsByPermissions();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    // this.roleName = this.authenticationService.getRoleNameByToken();
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      if (localStorage.getItem(AppConstants.ROUTE_LOGIN_FIRST_TIME_INDICATOR)) {
        localStorage.removeItem(AppConstants.ROUTE_LOGIN_FIRST_TIME_INDICATOR);
        window.location.reload();
      }
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.isClickedMenu.next(false);
      }
    });
  }

  filterMenuItemsByPermissions() {
    const userPermissions = JSON.parse(localStorage.getItem(AppConstants.PERMISSIONS)!) || [];
    const menuTitles = userPermissions.map((permission: any) => this.permissionMapping[permission]);

    const hasPermission = (menuItem: any) =>
      menuItem.children && menuItem.children.some((child: any) => menuTitles.includes(child.title));

    const expandedMenuTitles = this.menuItems.filter(hasPermission).map((parentItem) => parentItem.title);

    const filterMenu = (menuItem: any) => {
      this.handleAppResources(menuItem, menuTitles); //function to handle 'App Resources' only

      if (menuItem.children) {
        menuItem.children = menuItem.children.filter((child: any) => menuTitles.includes(child.title));
      }

      return menuTitles.includes(menuItem.title) || expandedMenuTitles.includes(menuItem.title);
    };
    return this.menuItems.filter(filterMenu);
  }

  includeAppResources(menuItem: any, menuTitles: any) {
    if (menuItem.title === Permission.LP_LIST) {
      if (menuItem.children) {
        menuItem.children.forEach((child: any) => {
          if (!menuTitles.includes(child.title)) {
            menuTitles.push(child.title);
          }
        });
      }
    }
  }

  //this function in filterMenuItemsByPermissions to handle 'App Resources'
  handleAppResources(menuItem: any, menuTitles: any) {
    this.includeAppResources(menuItem, menuTitles);
  }
}
