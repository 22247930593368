import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { PeriodicElement } from './about';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Status } from '@app/auth/typings/common-constants';
import { UpdateLandingPageComponent } from './components/update-landing-page/update-landing-page.component';
import { CreateLandingPageComponent } from './components/create-landing-page/create-landing-page.component';
import { AboutService } from './about.service';
import { environment } from '@env/environment';
import { PermissionCheckService } from '@app/@shared/services/permission-check.service';
import { PagePermission, Permission, PermissionName } from '@app/@shared/models/permissions.enum';
import { AvailablePermissions } from '@app/@shared/models/available-permissions';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  @ViewChild('conformPublishUnpublish') conformPublishUnpublish!: ElementRef;
  @ViewChild('confirmDelete') confirmDelete!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ELEMENT_DATA: PeriodicElement[] = [];
  displayedColumns: string[] = ['name', 'landingPageUrl', 'status', 'id'];
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  pageNumber: number = 0;
  pageSize: number = 50;
  faqContantList: any;
  isLoading = false;
  totalElements: number = 0;
  totalPages: number = 0;
  pageEvent!: PageEvent;
  status: string = '';
  entryId!: number;
  landingURL: string = environment.FRONTEND_BASE_URL + environment.FRONTEND_PATH_NAME;
  campaignURL: string = environment.FRONTEND_BASE_URL;
  availablePermissions: AvailablePermissions;

  constructor(
    private router: Router,
    private aboutService: AboutService,
    private modalService: NgbModal,
    private permissionCheckService: PermissionCheckService
  ) {
    this.availablePermissions = {
      create: false,
      update: false,
      status: false,
      delete: false,
    };
  }

  ngOnInit(): void {
    this.checkPermissions();
    this.diyEntryList();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  diyEntryList() {
    this.isLoading = true;
    this.ELEMENT_DATA = [];
    // this.totalPages = 0;
    this.aboutService.getPaginationLandingPageList(this.pageNumber, this.pageSize, '').subscribe(
      (res) => {
        if (res.items && res.items.length > 0) {
          res.items.forEach((element: any) => {
            this.ELEMENT_DATA.push({
              name: element.name,
              topBanner: element.topBanner,
              bottomBanner: element.bottomBanner,
              coupon: element.coupon,
              planIds: element.planIds,
              planDetails: element.planDetails,
              status: element.status,
              id: element.id,
              videoUrl: element.videoUrl,
            });
          });
        }
        this.faqContantList = res;
        this.totalPages = this.faqContantList.totalElements;
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageNumber = e.pageIndex;
    this.diyEntryList();
  }

  registerDIYSteps() {
    if (this.availablePermissions.create) {
      const modalRef = this.modalService.open(CreateLandingPageComponent, {
        windowClass: 'create-landing-page',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      });
      modalRef.componentInstance.isAddFaqEntry.subscribe((message: boolean) => {
        if (message) {
          this.modalService.dismissAll();
          this.ELEMENT_DATA = [];
          this.diyEntryList();
        }
      });
    }
  }

  editDIYEntry(entryId: number) {
    if (this.availablePermissions.update) {
      const modalRef = this.modalService.open(UpdateLandingPageComponent, {
        windowClass: 'update-landing-page',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      }); // 'md'
      modalRef.componentInstance.entryId = entryId;
      modalRef.componentInstance.isUpdateFaqEntry.subscribe((message: boolean) => {
        if (message) {
          this.modalService.dismissAll();
          this.ELEMENT_DATA = [];
          this.diyEntryList();
        }
      });
    }
  }

  confirmModal(event: any, entryId: number, status: string) {
    if (this.availablePermissions.status) {
      if (status === Status[Status.PUBLISHED]) {
        event.source.checked = true;
      } else {
        event.source.checked = false;
      }
      this.entryId = entryId;
      this.status = status === Status[Status.PUBLISHED] ? Status[Status.UNPUBLISHED] : Status[Status.PUBLISHED];
      this.modalService.open(this.conformPublishUnpublish, {
        windowClass: 'confirm-publish-unpublish',
        size: 'md',
        centered: true,
      });
    }
  }

  publishUnpublishEntry() {
    this.isLoading = true;
    let command: any = {
      id: this.entryId,
      status: this.status,
    };
    this.aboutService.updateStatusById(command).subscribe(
      (res) => {
        if (res.data && res.data !== null) {
          this.modalService.dismissAll();
          // this.ELEMENT_DATA = [];
          // this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
          this.diyEntryList();
        } else {
          this.isLoading = false;
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  confirmDeleteModal(entryId: number) {
    if (this.availablePermissions.delete) {
      this.entryId = entryId;
      this.modalService.open(this.confirmDelete, {
        windowClass: 'confirm-delete',
        size: 'md',
        centered: true,
      });
    }
  }

  deleteLandingPage() {
    this.isLoading = true;
    this.aboutService.deleteLandingPageById(this.entryId).subscribe(
      (res) => {
        if (res.data && res.data !== null) {
          this.modalService.dismissAll();
          // this.ELEMENT_DATA = [];
          // this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
          this.diyEntryList();
        } else {
          this.isLoading = false;
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  checkPermissions() {
    this.availablePermissions = {
      create: this.checkUserPermission(PermissionName[PermissionName.LP_CREATE]),
      update: this.checkUserPermission(PermissionName[PermissionName.LP_UPDATE]),
      status: this.checkUserPermission(PermissionName[PermissionName.LP_CHANGE_STATUS]),
      delete: this.checkUserPermission(PermissionName[PermissionName.LP_DELETE]),
    };
    // console.log(this.availablePermissions);
  }

  checkUserPermission(permission: any) {
    const result: boolean = this.permissionCheckService.hasPermission(permission);
    // console.log(`Permission ${permission}: ${result}`);
    return result;
  }
}
