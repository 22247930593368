import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MultiCoupon,
  MultiCouponList,
  PlanIdDTOList,
  SubscriptionPlanType,
  UpdateLandingPageCommand,
} from '@app/about/about';
import { AboutService } from '@app/about/about.service';
import { ApisService } from '@app/home/components/service/apis.service';
import { AwsService } from '@app/home/components/service/aws-service';
import { environment } from '@env/environment';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-update-landing-page',
  templateUrl: './update-landing-page.component.html',
  styleUrls: ['./update-landing-page.component.scss'],
})
export class UpdateLandingPageComponent implements OnInit, OnDestroy {
  @Input() entryId: number = 0;
  @Output() isUpdateFaqEntry: EventEmitter<boolean> = new EventEmitter(false);

  version: string | null = environment.version;
  error: string | undefined;
  updateDIYForm!: FormGroup;
  isLoading = false;
  foundErrorLogin = false;
  editorConfig: AngularEditorConfig = {};
  htmlContent: string = '';
  htmlContent2: string = '';
  htmlContent3: string = '';
  htmlContent4: string = '';
  categoryListAll: any[] = [];
  categoryList: any[] = [];
  subCategoryList: any[] = [];
  channelPartners: any[] = [];
  selectedCategoryValue: string = '';
  selectedSubCategoryValue: string = '';
  selectedOTTAPPValue: string = '';
  statusList: string[];
  imageURL1: string = '';
  imageURL2: string = '';
  isImageUrl1: boolean = false;
  isImageUrl2: boolean = false;
  imageUrlMsg: boolean = false;
  imageUrlErrMsg: string = '';
  cloudUrl: string;
  toolTipList: any[];
  selectedValue: any = null;
  // searchTxt: any = null;
  subscriptionList: any[] = [];
  errorMessage: any = null;
  selecteSubscriptionList: any[] = [];
  selectedColor = '#aec7ef';
  pickColorButton: boolean = true;
  isChecked: boolean = false;
  multiCouponList: MultiCouponList;
  couponEvents: any[];
  planIds: number[] = [];
  planIdDTOList: PlanIdDTOList[] = [];
  tempPlanList: any[] = [];

  public searchTxt: FormControl<string | null> = new FormControl<string>('');
  public filteredPlansMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private aboutService: AboutService,
    private modalService: NgbModal,
    private awsService: AwsService,
    private apisService: ApisService
  ) {
    this.cloudUrl = environment.CLOUD_URL;
    this.createForm();
    this.statusList = ['PUBLISHED', 'UNPUBLISHED'];
    this.toolTipList = [
      'assets/help_1.png',
      'assets/landing_help_1.png',
      'assets/landing_help_2.png',
      'assets/landing_help_3.png',
      'assets/landing_help_4.png',
      'assets/landing_help_5.png',
    ];
    this.couponEvents = [];
    this.multiCouponList = {
      multiCoupon: [],
    };
  }

  ngOnInit(): void {
    // this.editorConfigService();
    this.awsService.loadAWSConfig();
    this.getFaqEntryDetail();

    // listen for search field value changes
    this.searchTxt.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterBanksMulti();
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected filterBanksMulti() {
    if (!this.subscriptionList) {
      return;
    }
    // get the search keyword
    let search = this.searchTxt.value;
    if (!search) {
      this.filteredPlansMulti.next(this.subscriptionList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the plans by planID or planName
    this.filteredPlansMulti.next(
      this.subscriptionList.filter(
        (bank) =>
          bank.SubscriptionPlanName.toLowerCase().indexOf(search) > -1 ||
          bank.SubscriptionPlanID.toString().toLowerCase().indexOf(search) > -1
      )
    );
  }

  getAvailableSubscriptionPlanDetails() {
    this.selecteSubscriptionList = [];
    let tempPlanList: any[] = this.apisService.getDishPlanList();
    if (tempPlanList.length > 0) {
      tempPlanList.forEach((ele: any) => {
        this.subscriptionList.push(ele);
      });
      if (this.subscriptionList.length > 0) {
        // load the initial bank list
        this.filteredPlansMulti.next(this.subscriptionList.slice());
        this.subscriptionList = this.subscriptionList.sort((a: any, b: any) => {
          return a.SubscriptionGroupNo - b.SubscriptionGroupNo;
        });
        // this.subscriptionList.forEach((ele: any) => {
        //   this.updateDIYForm.value.planIds.forEach((element: any) => {
        //     // if (ele.SubscriptionPlanID === this.updateDIYForm.value.planIds) {
        //     // single
        //     if (ele.SubscriptionPlanID === element) {
        //       this.selecteSubscriptionList.push(ele);
        //     }
        //   });
        // });
      }
    }
  }

  change(event: any) {
    console.log('Multi Tags Input : ', event);
    if (event && event.length > 0) {
      this.couponEvents = event;
    } else {
      this.couponEvents = [];
    }
  }

  selectItem(event: any) {
    // console.log(event);
    // this.selecteSubscriptionList = [];
    // event.value.forEach((element: any) => {
    //   this.subscriptionList.forEach((ele: any) => {
    //     if (element === ele.SubscriptionPlanID) {
    //       this.selecteSubscriptionList.push(ele);
    //     }
    //   });
    // });
    // if (this.selecteSubscriptionList.length > 1) {
    //   this.isChecked = true;
    // } else {
    //   this.isChecked = false;
    // }
  }

  optionedValue(event: any, subscriptionDetail: any) {
    if (event.isUserInput) {
      if (event.source.selected === false) {
        var index = this.tempPlanList.findIndex((x) => x === subscriptionDetail.SubscriptionPlanID);
        if (index !== -1) {
          this.selecteSubscriptionList.splice(index, 1);
          this.tempPlanList.splice(index, 1);
        }
      } else {
        if (!this.tempPlanList.includes(subscriptionDetail.SubscriptionPlanID)) {
          this.tempPlanList.push(subscriptionDetail.SubscriptionPlanID);
          this.selecteSubscriptionList.push(subscriptionDetail);
        }
      }
    }
  }

  sortComparator(a: any, b: any) {
    return 1;
  }

  editorConfigService() {
    this.editorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '5rem',
      maxHeight: '0',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        { class: 'arial', name: 'Arial' },
        { class: 'times-new-roman', name: 'Times New Roman' },
        { class: 'calibri', name: 'Calibri' },
        { class: 'comic-sans-ms', name: 'Comic Sans MS' },
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText',
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      uploadUrl: 'v1/image',
      // upload: (file: File) => { ... },
      uploadWithCredentials: false,
      sanitize: true,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [['insertImage', 'insertVideo']],
    };
  }

  onFileChange1(event: any) {
    this.imageURL1 = '';
    this.isImageUrl1 = true;
    this.isLoading = true;
    if (event.target.files[0].type.indexOf('image/') >= 0) {
      if (event.target.id === 'imageUrls1') {
        // if ((event.target.files[0].size / 1024 / 1024) > 2) {
        //   this.imageUrlMsg = true;
        //   this.isImageUrl = false;
        //   this.isLoading = false;
        //   this.imageUrlErrMsg = "The file's size should be less from 2MB";
        // } else {
        this.imageUrlMsg = false;
        this.imageUrlErrMsg = '';
        this.awsService.uploadPhoto(event.target.files[0], 'images').then(
          (res: any) => {
            this.imageURL1 = this.cloudUrl + res.Key;
            this.isLoading = false;
            this.isImageUrl1 = false;
          },
          (error: any) => {
            this.isLoading = false;
            this.isImageUrl1 = false;
            console.error(error);
          }
        );
        // }
      }
    } else {
      if (event.target.id === 'imageUrls1') {
        this.imageUrlMsg = true;
        this.isLoading = false;
        this.isImageUrl1 = false;
        this.imageURL1 = '/assets/upload.png';
        this.imageUrlErrMsg = 'Please upload only image.';
      }
    }
  }

  onFileChange2(event: any) {
    this.imageURL2 = '';
    this.isImageUrl2 = true;
    this.isLoading = true;
    if (event.target.files[0].type.indexOf('image/') >= 0) {
      if (event.target.id === 'imageUrls2') {
        // if ((event.target.files[0].size / 1024 / 1024) > 2) {
        //   this.imageUrlMsg = true;
        //   this.isImageUrl = false;
        //   this.isLoading = false;
        //   this.imageUrlErrMsg = "The file's size should be less from 2MB";
        // } else {
        this.imageUrlMsg = false;
        this.imageUrlErrMsg = '';
        this.awsService.uploadPhoto(event.target.files[0], 'images').then(
          (res: any) => {
            this.imageURL2 = this.cloudUrl + res.Key;
            this.isLoading = false;
            this.isImageUrl2 = false;
          },
          (error: any) => {
            this.isLoading = false;
            this.isImageUrl2 = false;
            console.error(error);
          }
        );
        // }
      }
    } else {
      if (event.target.id === 'imageUrls2') {
        this.imageUrlMsg = true;
        this.isLoading = false;
        this.isImageUrl2 = false;
        this.imageURL2 = '/assets/upload.png';
        this.imageUrlErrMsg = 'Please upload only image.';
      }
    }
  }

  getFaqEntryDetail() {
    this.isLoading = true;
    this.isImageUrl1 = true;
    this.isImageUrl2 = true;
    this.aboutService.getLandingPageDetailById(this.entryId).subscribe(
      (res) => {
        this.getAvailableSubscriptionPlanDetails();
        if (res.data) {
          if (res.data.planIdDTOList && res.data.planIdDTOList.length > 0) {
            res.data.planIdDTOList = res.data.planIdDTOList.sort((a: any, b: any) => a.orders - b.orders);
          }
          this.updateDIYForm = this.formBuilder.group({
            name: new FormControl({ value: res.data.name, disabled: true }, Validators.required),
            topBanner: new FormControl({ value: res.data.topBanner, disabled: false }),
            bottomBanner: new FormControl({ value: res.data.bottomBanner, disabled: false }),
            coupon: new FormControl({ value: res.data.coupon, disabled: false }),
            planIds: new FormControl({ value: '', disabled: false }, Validators.required), // for now set single [0]
            campaignMessage: new FormControl({ value: res.data.campaignMessage, disabled: false }),
            subCampaignMessage: new FormControl({ value: res.data.subCampaignMessage, disabled: false }),
            couponValidity: new FormControl({ value: res.data.couponValidity, disabled: false }),
            videoUrl: new FormControl({ value: res.data.videoUrl, disabled: false }),
          });
          this.imageURL1 = res.data.topBanner;
          this.imageURL2 = res.data.bottomBanner;
          this.selectedColor = res.data.colour;
          if (res.data.planIdDTOList && res.data.planIdDTOList.length > 0) {
            res.data.planIdDTOList.forEach((element: any) => {
              this.subscriptionList.forEach((ele: any) => {
                if (ele.SubscriptionPlanID === element.planId) {
                  this.planIds.push(element.planId);
                  this.selecteSubscriptionList.push(ele);
                }
              });
            });
            this.selectedValue = this.planIds;
            this.updateDIYForm.value.planIds = this.planIds;
            this.tempPlanList = this.planIds;
          }
          if (res.data.multipleCouponMap && res.data.multipleCouponMap.length > 0) {
            this.isChecked = true;
            res.data.multipleCouponMap.forEach((ele: any) => {
              this.couponEvents.push(
                ele.coupon === '' || ele.coupon === null || ele.coupon === undefined ? 'NAC' : ele.coupon
              );
            });
          }
          // this.htmlContent = res.data.mainTitle;
          // this.htmlContent2 = res.data.description;
          // this.htmlContent3 = res.data.description2;
          // this.htmlContent4 = res.data.membershipTitle;
        }
        this.isLoading = false;
        this.isImageUrl1 = false;
        this.isImageUrl2 = false;
      },
      (error) => {
        this.isLoading = false;
        this.isImageUrl1 = false;
        this.isImageUrl2 = false;
      }
    );
  }

  updateDIYEntry() {
    this.isLoading = true;
    this.multiCouponList.multiCoupon = [];
    this.planIdDTOList = [];
    if (this.isChecked && this.updateDIYForm.value.planIds.length > 0) {
      // multiple packs
      this.updateDIYForm.value.planIds.forEach((planId: number, idx: number) => {
        let multiCoupon: MultiCoupon = {
          planId: planId,
          coupon:
            this.couponEvents.length > 0
              ? this.couponEvents[idx] !== undefined
                ? this.couponEvents[idx].toLowerCase() === 'nac'
                  ? 'NAC'
                  : this.couponEvents[idx]
                : 'NAC'
              : 'NAC',
        };
        this.multiCouponList.multiCoupon.push(multiCoupon);
      });
    }
    if (this.tempPlanList && this.tempPlanList.length > 0) {
      this.tempPlanList.forEach((ele: any, index: number) => {
        let planIdDTOList: PlanIdDTOList = {
          orders: index,
          planId: ele,
        };
        this.planIdDTOList.push(planIdDTOList);
      });
    }
    // if (this.isChecked && this.updateDIYForm.value.planIds !== '') { // single pack
    //   this.couponEvents.forEach((ele: any) => {
    //     let multiCoupon: MultiCoupon = {
    //       planId: this.updateDIYForm.value.planIds,
    //       coupon: ele && ele.toLowerCase().indexOf('nac') >= 0 ? 'NAC' : ele,
    //     };
    //     this.multiCouponList.multiCoupon.push(multiCoupon);
    //   });
    // }
    let command: UpdateLandingPageCommand = {
      topBanner: this.imageURL1,
      bottomBanner: this.imageURL2,
      coupon: this.isChecked ? '' : this.updateDIYForm.value.coupon,
      planIds: [],
      planIdDTOList: this.planIdDTOList,
      // planIds: this.updateDIYForm.value.planIds, // multiple
      multipleCouponMap: this.isChecked ? this.multiCouponList.multiCoupon : this.multiCouponList.multiCoupon,
      // planIds: [this.updateDIYForm.value.planIds], // single
      colour: this.selectedColor,
      id: this.entryId,
      campaignMessage: this.updateDIYForm.value.campaignMessage,
      subCampaignMessage: this.updateDIYForm.value.subCampaignMessage,
      couponValidity: this.updateDIYForm.value.couponValidity,
      videoUrl:
        this.updateDIYForm.value.videoUrl !== null &&
        this.updateDIYForm.value.videoUrl !== '' &&
        this.updateDIYForm.value.videoUrl.length > 0
          ? this.updateDIYForm.value.videoUrl
          : null,
    };
    if (command.topBanner.length > 0 && command.bottomBanner.length > 0) {
      command.videoUrl = this.updateDIYForm.value.planIds.length > 1 ? command.topBanner : null;
    }
    // if (this.updateDIYForm.valid && this.imageURL1.length > 0 && this.imageURL2.length > 0) {
    if (this.updateDIYForm.valid) {
      this.aboutService.updateLandingPage(command).subscribe(
        (res) => {
          if (res) {
            this.isUpdateFaqEntry.emit(true);
          }
          // this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    } else {
      this.isLoading = false;
    }
  }

  private createForm() {
    this.updateDIYForm = this.formBuilder.group({
      name: new FormControl({ value: '', disabled: true }, Validators.required),
      topBanner: new FormControl({ value: '', disabled: false }),
      bottomBanner: new FormControl({ value: '', disabled: false }),
      coupon: new FormControl({ value: '', disabled: false }),
      planIds: new FormControl({ value: '', disabled: false }, Validators.required),
      campaignMessage: new FormControl({ value: '', disabled: false }),
      subCampaignMessage: new FormControl({ value: '', disabled: false }),
      couponValidity: new FormControl({ value: '', disabled: false }),
      videoUrl: new FormControl({ value: null, disabled: false }),
    });
  }

  modalClose() {
    this.modalService.dismissAll();
  }
}
