import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-authentication-error',
  templateUrl: './authentication-error.component.html',
  styleUrls: ['./authentication-error.component.scss'],
})
export class AuthenticationErrorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  goToHome() {
    // this.menuService.navigateHome();
  }
}
