import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { ChangePasswordComponent } from './shell/change-password/change-password.component';
import { PagesAuthGuard } from './auth/pages-auth.guard';
import { AuthenticationErrorComponent } from './authentication-error/authentication-error.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'home',
      canActivate: [PagesAuthGuard],
      loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    },
    // {
    //   path: 'make-your-own-pack',
    //   canActivate: [PagesAuthGuard],
    //   loadChildren: () => import('./diy-steps/diy-steps.module').then((m) => m.DiyStepsModule),
    // },
    {
      path: 'banners',
      canActivate: [PagesAuthGuard],
      loadChildren: () => import('./banners/banners.module').then((m) => m.BannersModule),
    },
    {
      path: 'accounts',
      canActivate: [PagesAuthGuard],
      loadChildren: () => import('./accounts/accounts.module').then((m) => m.AccountsModule),
    },
    {
      path: 'landing-page',
      canActivate: [PagesAuthGuard],
      loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
    },
    {
      path: 'campaign-faq',
      canActivate: [PagesAuthGuard],
      loadChildren: () => import('./campaigns-faq/campaigns-faq.module').then((m) => m.CampaignsFaqModule),
    },
    {
      path: 'manage-campaigns',
      canActivate: [PagesAuthGuard],
      loadChildren: () => import('./manage-campaigns/manage-campaigns.module').then((m) => m.ManageCampaignsModule),
    },
    {
      path: 'landing-page-six',
      canActivate: [PagesAuthGuard],
      loadChildren: () => import('./landing-page-six/landing-page-six.module').then((m) => m.LandingPageSixModule),
    },
    {
      path: 'landing-page-ten',
      canActivate: [PagesAuthGuard],
      loadChildren: () => import('./landing-page-ten/landing-page-ten.module').then((m) => m.LandingPageTenModule),
    },
    {
      path: 'landing-page-seven',
      canActivate: [PagesAuthGuard],
      loadChildren: () =>
        import('./landing-page-seven/landing-page-seven.module').then((m) => m.LandingPageSevenModule),
    },
    {
      path: 'landing-page-eight',
      canActivate: [PagesAuthGuard],
      loadChildren: () =>
        import('./landing-page-eight/landing-page-eight.module').then((m) => m.LandingPageEightModule),
    },
    {
      path: 'winback-generate-url',
      canActivate: [PagesAuthGuard],
      loadChildren: () =>
        import('./winback-generate-url/winback-generate-url.module').then((m) => m.WinbackGenerateUrlModule),
    },
    {
      path: 'upgrade-pages',
      canActivate: [PagesAuthGuard],
      loadChildren: () => import('./upgrade-pages/upgrade-pages.module').then((m) => m.UpgradePagesModule),
    },
    {
      path: 'know-more-urls',
      canActivate: [PagesAuthGuard],
      loadChildren: () => import('./know-more-url/know-more-url.module').then((m) => m.KnowMoreUrlModule),
    },
    {
      path: 'landing-page-nine',
      canActivate: [PagesAuthGuard],
      loadChildren: () => import('./landing-page-nine/landing-page-nine.module').then((m) => m.LandingPageNineModule),
    },
    {
      path: 'change-password',
      component: ChangePasswordComponent,
    },
    { path: '403', component: AuthenticationErrorComponent },
  ]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
