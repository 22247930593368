import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PortalUpdatesComponent } from './components/portal-updates/portal-updates.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@app/i18n';
import { AuthRoutingModule } from '@app/auth/auth-routing.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CreateCustomPackComponent } from './components/dashboard/create-custom-pack/create-custom-pack.component';
import { UpdateCustomPackComponent } from './components/dashboard/update-custom-pack/update-custom-pack.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { SearchPipe } from '@app/shell/search.pipe';
import { UpdateCustomFlexiPackComponent } from './components/portal-updates/update-custom-flexi-pack/update-custom-flexi-pack.component';
import { MatOptionModule } from '@angular/material/core';
import { CreateCustomFlexiPackComponent } from './components/portal-updates/create-custom-flexi-pack/create-custom-flexi-pack.component';
import { ColorPickerModule, ColorPickerService } from 'ngx-color-picker';
import { MatIconModule } from '@angular/material/icon';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    HomeRoutingModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    I18nModule,
    AuthRoutingModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatOptionModule,
    ColorPickerModule,
    MatIconModule,
  ],
  declarations: [
    HomeComponent,
    DashboardComponent,
    PortalUpdatesComponent,
    CreateCustomPackComponent,
    UpdateCustomPackComponent,
    CreateCustomFlexiPackComponent,
    UpdateCustomFlexiPackComponent,
  ],
  exports: [
    CreateCustomPackComponent,
    UpdateCustomPackComponent,
    CreateCustomFlexiPackComponent,
    UpdateCustomFlexiPackComponent,
  ],
  providers: [],
})
export class HomeModule {}
