import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PeriodicElement, Type, UpdateCustomPackCommand } from '../model/apis';
import { ApisService } from '../service/apis.service';
import { CreateCustomPackComponent } from './create-custom-pack/create-custom-pack.component';
import { UpdateCustomPackComponent } from './update-custom-pack/update-custom-pack.component';
import { Status } from '@app/auth/typings/common-constants';
import { PermissionCheckService } from '@app/@shared/services/permission-check.service';
import { AvailablePermissions } from '@app/@shared/models/available-permissions';
import { PermissionName } from '@app/@shared/models/permissions.enum';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @ViewChild('conformPublishUnpublish') conformPublishUnpublish!: ElementRef;
  @ViewChild('conformDelete') conformDelete!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ELEMENT_DATA: PeriodicElement[] = [];
  displayedColumns: string[] = ['customId', 'displayOrder', 'title', 'subTitle', 'customPackStatus', 'id']; // 'saveUptoText', 'buttonText'
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  pageNumber: number = 0;
  pageSize: number = 50;
  customPacksList: any = null;
  isLoading = false;
  totalElements: number = 0;
  totalPages: number = 0;
  pageEvent!: PageEvent;
  entryId!: number;
  status: string = '';
  isOneTimeEvent: boolean;
  availablePermissions: AvailablePermissions;

  constructor(
    private apisService: ApisService,
    private modalService: NgbModal,
    private permissionCheckService: PermissionCheckService
  ) {
    this.isOneTimeEvent = false;
    this.availablePermissions = {
      create: false,
      update: false,
      status: false,
      delete: false,
    };
  }

  ngOnInit(): void {
    this.checkPermissions();
    this.ELEMENT_DATA = [];
    this.customPackList();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  customPackList() {
    this.isLoading = true;
    this.ELEMENT_DATA = [];
    // this.totalPages = 0;
    this.apisService.getPaginationcreateCustomPackList(this.pageNumber, this.pageSize, '', Type[Type.CUSTOM]).subscribe(
      (res) => {
        if (res.items && res.items.length > 0) {
          res.items.forEach((element: any) => {
            this.ELEMENT_DATA.push({
              buttonText: element.buttonText,
              customPackStatus: element.customPackStatus,
              displayOrder: element.displayOrder,
              id: element.id,
              planIds: element.planIds,
              saveUptoText: element.saveUptoText,
              subTitle: element.subTitle,
              title: element.title,
              type: element.type,
            });
          });
        }
        this.customPacksList = res;
        // this.ELEMENT_DATA = this.ELEMENT_DATA.sort((a: any, b: any) => {
        //   return a.displayOrder - b.displayOrder;
        // });
        this.totalPages = res.totalElements;
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageNumber = e.pageIndex;
    this.customPackList();
  }

  createCustomPack() {
    if (this.availablePermissions.create) {
      const modalRef = this.modalService.open(CreateCustomPackComponent, {
        windowClass: 'create-custom-pack',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      });
      modalRef.componentInstance.isAddFaqEntry.subscribe((message: boolean) => {
        if (message) {
          this.modalService.dismissAll();
          this.ELEMENT_DATA = [];
          this.customPackList();
        }
      });
    }
  }

  editFaqEntry(entryId: number) {
    if (this.availablePermissions.update) {
      const modalRef = this.modalService.open(UpdateCustomPackComponent, {
        windowClass: 'update-custom-pack',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      });
      modalRef.componentInstance.entryId = entryId;
      modalRef.componentInstance.isUpdateFaqEntry.subscribe((message: boolean) => {
        if (message) {
          this.modalService.dismissAll();
          this.ELEMENT_DATA = [];
          this.customPackList();
        }
      });
    }
  }

  confirmModal(event: any, entryId: number, status: string) {
    if (this.availablePermissions.status) {
      if (status === Status[Status.PUBLISHED]) {
        event.source.checked = true;
      } else {
        event.source.checked = false;
      }
      this.entryId = entryId;
      this.status = status === Status[Status.PUBLISHED] ? Status[Status.UNPUBLISHED] : Status[Status.PUBLISHED];
      this.modalService.open(this.conformPublishUnpublish, {
        windowClass: 'confirm-publish-unpublish',
        size: 'md',
        centered: true,
      });
    }
  }

  publishUnpublishEntry() {
    this.isLoading = true;
    this.apisService.updateStatusById(this.entryId, this.status).subscribe(
      (res) => {
        if (res) {
          this.modalService.dismissAll();
          this.ELEMENT_DATA = [];
          this.customPackList();
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  confirmDeleteModal(entryId: number) {
    if (this.availablePermissions.delete) {
      this.entryId = entryId;
      this.modalService.open(this.conformDelete, {
        windowClass: 'confirm-delete',
        size: 'md',
        centered: true,
      });
    }
  }

  deletePack() {
    this.isLoading = true;
    this.apisService.deleteCustomPackById(this.entryId).subscribe(
      (res) => {
        if (res) {
          this.modalService.dismissAll();
          this.ELEMENT_DATA = [];
          this.customPackList();
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  checkNumber(evt: any): any {
    // if (evt.srcElement.value.length == 0 && evt.which == 48) {
    if (evt.srcElement.value.length == 0 && evt.which == 47) {
      evt.preventDefault();
      return false;
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keydownNumberEvent(event: any, entryId: number) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    setTimeout(() => {
      if (event.target.value !== '' && !isNaN(event.target.value) && !this.isOneTimeEvent) {
        this.isOneTimeEvent = true;
        this.getCustomPackDetail(entryId, event.target.value);
      } else {
        event.preventDefault();
      }
    }, 3000);
  }

  getCustomPackDetail(entryId: number, dOInput: number) {
    this.isLoading = true;
    this.apisService.getCustomPackDetailById(entryId).subscribe(
      (res) => {
        if (res.data) {
          let command: UpdateCustomPackCommand = {
            buttonText: res.data.buttonText,
            customPackStatus: res.data.customPackStatus,
            displayOrder: dOInput,
            title: res.data.title,
            subTitle: res.data.subTitle,
            saveUptoText: res.data.saveUptoText,
            planIds: res.data.planIds,
            id: res.data.id,
            type: res.data.type,
          };
          this.updateCustomPack(command);
        }
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  updateCustomPack(command: UpdateCustomPackCommand) {
    this.isLoading = true;
    this.apisService.updateCustomPack(command).subscribe(
      (res) => {
        this.isLoading = false;
        this.isOneTimeEvent = false;
        this.ELEMENT_DATA = [];
        this.customPackList();
      },
      (error) => {
        this.isLoading = false;
        this.isOneTimeEvent = false;
      }
    );
  }

  checkPermissions() {
    this.availablePermissions = {
      create: this.checkUserPermission(PermissionName[PermissionName.CUSTOM_PACKS_FIXED_CREATE]),
      update: this.checkUserPermission(PermissionName[PermissionName.CUSTOM_PACKS_FIXED_UPDATE]),
      status: this.checkUserPermission(PermissionName[PermissionName.CUSTOM_PACKS_FIXED_CHANGE_STATUS]),
      delete: this.checkUserPermission(PermissionName[PermissionName.CUSTOM_PACKS_FIXED_DELETE]),
    };
    // console.log(this.availablePermissions);
  }

  checkUserPermission(permission: any) {
    const result: boolean = this.permissionCheckService.hasPermission(permission);
    // console.log(`Permission ${permission}: ${result}`);
    return result;
  }
}
