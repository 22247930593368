import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AppConstants } from './typings/common-constants';
import { PagePermission } from '@app/@shared/models/permissions.enum';
@Injectable({
  providedIn: 'root',
})
export class PagesAuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const pageToPermissionMapping: any = {
      home: PagePermission.CustomPacks,
      banners: PagePermission.Banners,
      // 'make-your-own-pack': PagePermission.MakeYourOwnPack,
      'landing-page': PagePermission.LandingPageList,
      'manage-campaigns': PagePermission.ManageLandingPage,
      'landing-page-six': PagePermission.LandingPage6List,
      'landing-page-seven': PagePermission.LandingPage7List,
      'landing-page-eight': PagePermission.LandingPage8List,
      'landing-page-nine': PagePermission.LandingPage9List,
      'landing-page-ten': PagePermission.LandingPage10List,
      'campaign-faq': PagePermission.LandingPageFaq,
      'winback-generate-url': PagePermission.WinbackUrls,
      'upgrade-pages': PagePermission.UpgragePages,
      accounts: PagePermission.CMSUsers,
    };

    const permissionList = localStorage.getItem(AppConstants.PERMISSIONS);

    const pageMenuItem = route.routeConfig!.path;

    // console.log('Page Menu Item:', route.routeConfig!.path);
    try {
      if (pageMenuItem && pageToPermissionMapping[pageMenuItem]) {
        const requiredPermission = pageToPermissionMapping[pageMenuItem];
        if (!permissionList!.includes(requiredPermission)) {
          this.router.navigate(['/403']);
          return false;
        }
      }
    } catch (err) {
      console.log(err);
      localStorage.clear();
      this.router.navigate(['/login']);
    }

    return true;
  }
}
