import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AppConstants } from '@app/auth/typings/common-constants';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { CreateCustomPackCommand, UpdateCustomPackCommand } from '../model/apis';
import { RegisterFAQEntry, UpdateFAQEntry } from '@app/campaigns-faq/faq';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ApisService {
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: object, private httpClient: HttpClient) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  getAuthToken() {
    if (localStorage.getItem(AppConstants.AUTH_HEADER_KEY)) {
      let authToken: any = localStorage.getItem(AppConstants.AUTH_HEADER_KEY);
      return authToken;
    }
  }

  createCustomPack(command: any): Observable<any> {
    let httpHeader = new HttpHeaders().set('Authorization', this.getAuthToken());
    return this.httpClient
      .post(environment.API_BASE_URL + environment.API_VERSION + 'packs/createCustomPack', command, {
        headers: httpHeader,
      })
      .pipe((res) => {
        return res;
      });
  }

  getCustomPackDetailById(id: number): Observable<any> {
    let httpHeader = new HttpHeaders().set('Authorization', this.getAuthToken());
    return this.httpClient
      .get(environment.API_BASE_URL + environment.API_VERSION + 'packs/getCustomPackById?packId=' + id, {
        headers: httpHeader,
      })
      .pipe((res) => {
        return res;
      });
  }

  updateCustomPack(command: any): Observable<any> {
    let httpHeader = new HttpHeaders().set('Authorization', this.getAuthToken());
    return this.httpClient
      .patch(environment.API_BASE_URL + environment.API_VERSION + 'packs/updateCustomPack', command, {
        headers: httpHeader,
      })
      .pipe((res) => {
        return res;
      });
  }

  getCustomSubscriptionPackListByCustomPackId(customPackId: number): Observable<any> {
    let httpHeader = new HttpHeaders().set('Authorization', this.getAuthToken());
    return this.httpClient
      .get(
        environment.API_BASE_URL +
          environment.API_VERSION +
          'packs/getCustomSubscriptionPackList?customPackId=' +
          customPackId,
        { headers: httpHeader }
      )
      .pipe((res) => {
        return res;
      });
  }

  getPaginationcreateCustomPackList(
    pageNumber: number,
    pageSize: number,
    status: string,
    type: string
  ): Observable<any> {
    let httpHeader = new HttpHeaders().set('Authorization', this.getAuthToken());
    return this.httpClient
      .get(
        environment.API_BASE_URL +
          environment.API_VERSION +
          'packs/listAll?status=' +
          status +
          '&page=' +
          pageNumber +
          '&size=' +
          pageSize +
          '&type=' +
          type,
        { headers: httpHeader }
      )
      .pipe((res) => {
        return res;
      });
  }

  stringTrim(str: string, length?: number): string {
    if (length) {
      if (str && str.length > length) {
        return str.substr(0, length) + '...';
      } else {
        return str;
      }
    } else {
      if (str && str.length > 20) {
        return str.substr(0, 20) + '...';
      } else {
        return str;
      }
    }
  }

  updateStatusById(id: number, status: string): Observable<any> {
    let httpHeader = new HttpHeaders().set('Authorization', this.getAuthToken());
    return this.httpClient
      .put(
        environment.API_BASE_URL + environment.API_VERSION + 'packs/updateStatus?id=' + id + '&status=' + status,
        null,
        { headers: httpHeader }
      )
      .pipe((res) => {
        return res;
      });
  }

  deleteCustomPackById(packId: number): Observable<any> {
    let httpHeader = new HttpHeaders().set('Authorization', this.getAuthToken());
    return this.httpClient
      .delete(environment.API_BASE_URL + environment.API_VERSION + 'packs/deleteCustomPack?packId=' + packId, {
        headers: httpHeader,
      })
      .pipe((res) => {
        return res;
      });
  }

  getAvailableSubscriptionPlanDetails(): Observable<any> {
    let httpHeader = new HttpHeaders().set('SOURCE', '');
    return this.httpClient
      .post(
        environment.API_BASE_URL + environment.API_VERSION + 'flexi-plan/getAvailableSubscriptionPlanDetails',
        { Source: '' },
        {
          headers: httpHeader,
        }
      )
      .pipe((res) => {
        return res;
      });
  }

  registerFAQEntry(registerFAQEntry: RegisterFAQEntry): Observable<any> {
    let httpHeader = new HttpHeaders()
      .set('Authorization', this.getAuthToken())
      .set('Content-Type', 'application/json');
    return this.httpClient
      .post(environment.API_BASE_URL + environment.API_VERSION + 'cms/faqs/create', registerFAQEntry, {
        headers: httpHeader,
      })
      .pipe((res) => {
        return res;
      });
  }

  updateFAQEntry(updateFAQEntry: UpdateFAQEntry): Observable<any> {
    let httpHeader = new HttpHeaders()
      .set('Authorization', this.getAuthToken())
      .set('Content-Type', 'application/json');
    return this.httpClient
      .put(environment.API_BASE_URL + environment.API_VERSION + 'cms/faqs/update-faq', updateFAQEntry, {
        headers: httpHeader,
      })
      .pipe((res) => {
        return res;
      });
  }

  getFAQEntryDetail(entryId: number): Observable<any> {
    let httpHeader = new HttpHeaders()
      .set('Authorization', this.getAuthToken())
      .set('Content-Type', 'application/json');
    return this.httpClient
      .get(environment.API_BASE_URL + environment.API_VERSION + 'cms/faqs/get-by-id?faqsId=' + entryId, {
        headers: httpHeader,
      })
      .pipe((res) => {
        return res;
      });
  }

  getPaginationFAQEntryList(pageNumber: number, pageSize: number): Observable<any> {
    let httpHeader = new HttpHeaders()
      .set('Authorization', this.getAuthToken())
      .set('Content-Type', 'application/json');
    return this.httpClient
      .get(
        environment.API_BASE_URL +
          environment.API_VERSION +
          'cms/faqs/list-faqs?page=' +
          pageNumber +
          '&size=' +
          pageSize,
        { headers: httpHeader }
      )
      .pipe((res) => {
        return res;
      });
  }

  getLandingPageNameList(): Observable<any> {
    let httpHeader = new HttpHeaders()
      .set('Authorization', this.getAuthToken())
      .set('Content-Type', 'application/json');
    return this.httpClient
      .get(environment.API_BASE_URL + environment.API_VERSION + 'cms/faqs/list-landing-page', { headers: httpHeader })
      .pipe((res) => {
        return res;
      });
  }

  ChangeEntryStatus(entryId: number, status: string): Observable<any> {
    let httpHeader = new HttpHeaders()
      .set('Authorization', this.getAuthToken())
      .set('Content-Type', 'application/json');
    return this.httpClient
      .patch(
        environment.API_BASE_URL +
          environment.API_VERSION +
          'cms/faqs/change-faq-status?faqsId=' +
          entryId +
          '&status=' +
          status,
        null,
        { headers: httpHeader }
      )
      .pipe((res) => {
        return res;
      });
  }

  getDishPlanList(): any[] {
    if (this.isBrowser) {
      if (
        localStorage.getItem(AppConstants.DISH_PLAN_LIST) &&
        localStorage.getItem(AppConstants.DISH_PLAN_LIST) !== '' &&
        localStorage.getItem(AppConstants.DISH_PLAN_LIST) !== null
      ) {
        return JSON.parse(localStorage.getItem(AppConstants.DISH_PLAN_LIST)!);
      } else {
        return [];
      }
    }
    return [];
  }
}
