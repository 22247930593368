import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateLandingPageCommand, MultiCoupon, MultiCouponList, PlanIdDTOList } from '@app/about/about';
import { AboutService } from '@app/about/about.service';
import { ApisService } from '@app/home/components/service/apis.service';
import { AwsService } from '@app/home/components/service/aws-service';
import { environment } from '@env/environment';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-create-landing-page',
  templateUrl: './create-landing-page.component.html',
  styleUrls: ['./create-landing-page.component.scss'],
})
export class CreateLandingPageComponent implements OnInit, OnDestroy {
  @Output() isAddFaqEntry: EventEmitter<boolean> = new EventEmitter(false);
  version: string | null = environment.version;
  error: string | undefined;
  registerDIYForm!: FormGroup;
  isLoading = false;
  foundErrorLogin = false;
  editorConfig: AngularEditorConfig = {};
  htmlContent: string = '';
  htmlContent2: string = '';
  htmlContent3: string = '';
  htmlContent4: string = '';
  categoryListAll: any[] = [];
  categoryList: any[] = [];
  subCategoryList: any[] = [];
  channelPartners: any[] = [];
  selectedCategoryValue: string = '';
  selectedSubCategoryValue: string = '';
  selectedOTTAPPValue: string = '';
  statusList: string[];
  imageUrl1: string = '';
  imageUrl2: string = '';
  cloudUrl: string;
  isImageUrl1: boolean = false;
  isImageUrl2: boolean = false;
  toolTipList: any[];
  selectedValue: any = null;
  // searchTxt: any = null;
  subscriptionList: any[] = [];
  errorMessage: any = null;
  selectedColor = '#aec7ef';
  pickColorButton: boolean = true;
  couponEvents: any[];
  multiCouponList: MultiCouponList;
  isChecked: boolean = false;
  selectedSubscriptionList: any[] = [];
  planIdDTOList: PlanIdDTOList[] = [];
  tempPlanList: any[] = [];

  public searchTxt: FormControl<string | null> = new FormControl<string>('');
  public filteredPlansMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private aboutService: AboutService,
    private modalService: NgbModal,
    private awsService: AwsService,
    private apisService: ApisService
  ) {
    this.cloudUrl = environment.CLOUD_URL;
    this.createForm();
    this.statusList = ['PUBLISHED', 'UNPUBLISHED'];
    this.toolTipList = [
      'assets/help_1.png',
      'assets/landing_help_1.png',
      'assets/landing_help_2.png',
      'assets/landing_help_3.png',
      'assets/landing_help_4.png',
      'assets/landing_help_5.png',
    ];
    this.couponEvents = [];
    this.multiCouponList = {
      multiCoupon: [],
    };
  }

  ngOnInit(): void {
    // this.editorConfigService();
    this.awsService.loadAWSConfig();
    this.getAvailableSubscriptionPlanDetails();

    // listen for search field value changes
    this.searchTxt.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterBanksMulti();
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected filterBanksMulti() {
    if (!this.subscriptionList) {
      return;
    }
    // get the search keyword
    let search = this.searchTxt.value;
    if (!search) {
      this.filteredPlansMulti.next(this.subscriptionList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the plans by planID or planName
    this.filteredPlansMulti.next(
      this.subscriptionList.filter(
        (bank) =>
          bank.SubscriptionPlanName.toLowerCase().indexOf(search) > -1 ||
          bank.SubscriptionPlanID.toString().toLowerCase().indexOf(search) > -1
      )
    );
  }

  getAvailableSubscriptionPlanDetails() {
    this.selectedSubscriptionList = [];
    let tempPlanList: any[] = this.apisService.getDishPlanList();
    if (tempPlanList.length > 0) {
      tempPlanList.forEach((ele: any) => {
        this.subscriptionList.push(ele);
      });
      if (this.subscriptionList.length > 0) {
        // load the initial bank list
        this.filteredPlansMulti.next(this.subscriptionList.slice());
        this.subscriptionList = this.subscriptionList.sort((a: any, b: any) => {
          return a.SubscriptionGroupNo - b.SubscriptionGroupNo;
        });
      }
    }
  }

  selectItem(event: any) {
    // this.selectedSubscriptionList = [];
    // event.value.forEach((element: any) => {
    //   this.subscriptionList.forEach((ele: any) => {
    //     if (element === ele.SubscriptionPlanID) {
    //       this.selectedSubscriptionList.push(ele);
    //     }
    //   });
    // });
    // if (this.selectedSubscriptionList.length > 1) {
    //   this.isChecked = true;
    // } else {
    //   this.isChecked = false;
    // }
  }

  optionedValue(event: any, subscriptionDetail: any) {
    if (event.isUserInput) {
      if (event.source.selected === false) {
        var index = this.tempPlanList.findIndex((x) => x === subscriptionDetail.SubscriptionPlanID);
        if (index !== -1) {
          this.selectedSubscriptionList.splice(index, 1);
          this.tempPlanList.splice(index, 1);
        }
      } else {
        if (!this.tempPlanList.includes(subscriptionDetail.SubscriptionPlanID)) {
          this.tempPlanList.push(subscriptionDetail.SubscriptionPlanID);
          this.selectedSubscriptionList.push(subscriptionDetail);
        }
      }
    }
  }

  sortComparator(a: any, b: any) {
    return 1;
  }

  editorConfigService() {
    this.editorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '5rem',
      maxHeight: '0',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        { class: 'arial', name: 'Arial' },
        { class: 'times-new-roman', name: 'Times New Roman' },
        { class: 'calibri', name: 'Calibri' },
        { class: 'comic-sans-ms', name: 'Comic Sans MS' },
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText',
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      uploadUrl: 'v1/image',
      // upload: (file: File) => { ... },
      uploadWithCredentials: false,
      sanitize: true,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [['insertImage', 'insertVideo']],
    };
  }

  change(event: any) {
    console.log('Multi Tags Input : ', event);
    if (event && event.length > 0) {
      this.couponEvents = event;
    } else {
      this.couponEvents = [];
    }
  }

  registerNewDIY() {
    this.isLoading = true;
    this.errorMessage = null;
    this.multiCouponList.multiCoupon = [];
    this.planIdDTOList = [];
    this.registerDIYForm.value.name = this.registerDIYForm.value.name.trim();
    if (this.registerDIYForm.value.name.indexOf(' ') >= 0) {
      let tempData: string = '';
      let tempArray: any[] = this.registerDIYForm.value.name.split(' ');
      tempArray.forEach((ele: any) => {
        console.log(ele);
        tempData += ele;
      });
      this.registerDIYForm.value.name = tempData;
    }
    if (this.isChecked && this.registerDIYForm.value.planIds.length > 0) {
      // multiple packs
      this.registerDIYForm.value.planIds.forEach((planId: number, idx: number) => {
        let multiCoupon: MultiCoupon = {
          planId: planId,
          coupon:
            this.couponEvents.length > 0
              ? this.couponEvents[idx] !== undefined
                ? this.couponEvents[idx].toLowerCase() === 'nac'
                  ? 'NAC'
                  : this.couponEvents[idx]
                : 'NAC'
              : 'NAC',
        };
        this.multiCouponList.multiCoupon.push(multiCoupon);
      });
    }
    if (this.tempPlanList && this.tempPlanList.length > 0) {
      this.tempPlanList.forEach((ele: any, index: number) => {
        let planIdDTOList: PlanIdDTOList = {
          orders: index,
          planId: ele,
        };
        this.planIdDTOList.push(planIdDTOList);
      });
    }
    // if (this.isChecked && this.registerDIYForm.value.planIds !== '') {
    //   this.couponEvents.forEach((ele: any) => {
    //     let multiCoupon: MultiCoupon = {
    //       planId: this.registerDIYForm.value.planIds,
    //       coupon: ele && ele.toLowerCase().indexOf('nac') >= 0 ? 'NAC' : ele,
    //     };
    //     this.multiCouponList.multiCoupon.push(multiCoupon);
    //   });
    // }
    let command: CreateLandingPageCommand = {
      name: this.registerDIYForm.value.name.toLowerCase(),
      topBanner: this.imageUrl1,
      bottomBanner: this.imageUrl2,
      coupon: this.isChecked ? '' : this.registerDIYForm.value.coupon,
      multipleCouponMap: this.isChecked ? this.multiCouponList.multiCoupon : this.multiCouponList.multiCoupon,
      planIds: [],
      planIdDTOList: this.planIdDTOList,
      // planIds: this.registerDIYForm.value.planIds, // multiple
      // planIds: [this.registerDIYForm.value.planIds], // single
      colour: this.selectedColor,
      campaignMessage: this.registerDIYForm.value.campaignMessage,
      subCampaignMessage: this.registerDIYForm.value.subCampaignMessage,
      couponValidity: this.registerDIYForm.value.couponValidity,
      videoUrl:
        this.registerDIYForm.value.videoUrl !== null &&
        this.registerDIYForm.value.videoUrl !== '' &&
        this.registerDIYForm.value.videoUrl.length > 0
          ? this.registerDIYForm.value.videoUrl
          : null,
    };
    if (command.topBanner.length > 0 && command.bottomBanner.length > 0) {
      command.videoUrl = this.registerDIYForm.value.planIds.length > 1 ? command.topBanner : null;
    }
    // if (this.registerDIYForm.valid && this.imageUrl1.length > 0 && this.imageUrl2.length > 0) {
    if (this.registerDIYForm.valid) {
      this.aboutService.createLandingPage(command).subscribe(
        (res) => {
          if (res) {
            this.isAddFaqEntry.next(true);
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          if (error.error && error.error.errorMessage) {
            this.errorMessage = 'Your entered Name is already exist, Please try other Name.'; // error.error.errorMessage;
            setTimeout(() => {
              this.errorMessage = null;
            }, 4000);
          }
        }
      );
    } else {
      this.isLoading = false;
    }
  }

  uploadTopBanner(event: any) {
    console.log(event);
    this.imageUrl1 = '';
    this.isLoading = true;
    this.isImageUrl1 = true;
    if (event.target.files.length > 0 && event.target.files[0].type.indexOf('image/') >= 0) {
      this.awsService.uploadPhoto(event.target.files[0], 'images').then(
        (res: any) => {
          this.imageUrl1 = this.cloudUrl + res.Key;
          this.isLoading = false;
          this.isImageUrl1 = false;
        },
        (error: any) => {
          this.imageUrl1 = '';
          this.isLoading = false;
          this.isImageUrl1 = false;
          console.error(error);
        }
      );
    } else {
      this.imageUrl1 = '';
      this.isLoading = false;
      this.isImageUrl1 = false;
    }
  }

  uploadBottomBanner(event: any) {
    console.log(event);
    this.imageUrl2 = '';
    this.isLoading = true;
    this.isImageUrl2 = true;
    if (event.target.files.length > 0 && event.target.files[0].type.indexOf('image/') >= 0) {
      this.awsService.uploadPhoto(event.target.files[0], 'images').then(
        (res: any) => {
          this.imageUrl2 = this.cloudUrl + res.Key;
          this.isLoading = false;
          this.isImageUrl2 = false;
        },
        (error: any) => {
          this.imageUrl2 = '';
          this.isLoading = false;
          this.isImageUrl2 = false;
          console.error(error);
        }
      );
    } else {
      this.imageUrl2 = '';
      this.isLoading = false;
      this.isImageUrl2 = false;
    }
  }

  private createForm() {
    this.registerDIYForm = this.formBuilder.group({
      name: ['', Validators.required],
      topBanner: [''],
      bottomBanner: [''],
      coupon: [''],
      planIds: ['', Validators.required],
      campaignMessage: [''],
      subCampaignMessage: [''],
      couponValidity: [''],
      videoUrl: [null],
    });
  }

  modalClose() {
    this.modalService.dismissAll();
  }
}
