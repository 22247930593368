<div class="container-fluid" [ngStyle]="{ 'margin-top': !availablePermissions.create ? '30px' : '' }">
  <div class="dashboard">
    <div class="row">
      <div class="col-md-12 rightSide" *ngIf="availablePermissions.create">
        <button class="btn btn-primary" (click)="createCustomPack()">Create New Custom Pack</button>
      </div>
    </div>
    <div class="row">
      <div class="mat-elevation-z8">
        <table style="table-layout: fixed" mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="customId">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>

          <ng-container matColumnDef="displayOrder">
            <th mat-header-cell *matHeaderCellDef>Order No.</th>
            <td mat-cell *matCellDef="let element">
              <input
                type="text"
                [value]="element.displayOrder"
                class="display-order-input"
                (keypress)="checkNumber($event); keyPress($event)"
                (keydown)="keydownNumberEvent($event, element.id)"
                min="1"
                max="3"
                minlength="1"
                maxlength="3"
              />
            </td>
          </ng-container>

          <ng-container matColumnDef="flexiLeftText">
            <th mat-header-cell *matHeaderCellDef>Left Title</th>
            <td mat-cell *matCellDef="let element">{{ element.flexiLeftText }}</td>
          </ng-container>

          <ng-container matColumnDef="flexiRightText1">
            <th mat-header-cell *matHeaderCellDef>Right First Title</th>
            <td mat-cell *matCellDef="let element">{{ element.flexiRightText1 }}</td>
          </ng-container>

          <ng-container matColumnDef="flexiRightText2">
            <th mat-header-cell *matHeaderCellDef>Right Second Title</th>
            <td mat-cell *matCellDef="let element">{{ element.flexiRightText2 }}</td>
          </ng-container>

          <!-- <ng-container matColumnDef="saveUptoText">
            <th mat-header-cell *matHeaderCellDef>Save Up To Text</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element.saveUptoText"></td>
          </ng-container> -->

          <!-- <ng-container matColumnDef="buttonText">
            <th mat-header-cell *matHeaderCellDef>Button Text</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element.buttonText"></td>
          </ng-container> -->

          <ng-container matColumnDef="customPackStatus">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element.customPackStatus"></td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
              <span
                [title]="availablePermissions.update ? 'Edit Or View' : ''"
                [ngStyle]="{
                  cursor: availablePermissions.update ? 'pointer' : 'default',
                  opacity: availablePermissions.update ? '1' : '0.5'
                }"
                class="material-icons"
                (click)="editFaqEntry(element.id)"
                >edit_note</span
              >&ensp;
              <span
                class="slider-toggle-btn"
                [title]="
                  availablePermissions.status
                    ? element.customPackStatus !== 'PUBLISHED'
                      ? 'Publish'
                      : 'Unpublish'
                    : ''
                "
              >
                <mat-slide-toggle
                  [disabled]="!availablePermissions.status"
                  [checked]="element.customPackStatus === 'PUBLISHED' ? true : false"
                  (change)="confirmModal($event, element.id, element.customPackStatus)"
                ></mat-slide-toggle> </span
              >&ensp;&ensp;&ensp;&ensp;&ensp;&nbsp;
              <span
                *ngIf="availablePermissions.delete"
                [title]="availablePermissions.delete ? 'Delete' : ''"
                class="material-icons"
                [ngStyle]="{
                  cursor: availablePermissions.delete ? 'pointer' : 'default',
                  opacity: availablePermissions.delete ? '1' : '0.5'
                }"
                (click)="confirmDeleteModal(element.id)"
                >delete_outline</span
              >
              <!-- <button class="btn btn-primary" (click)="editFaqEntry(element.id)">Edit / View</button>&ensp;
              <button class="btn btn-primary" (click)="confirmModal(element.id, element.customPackStatus)">
                {{ element.customPackStatus === 'PUBLISHED' ? 'Unpublish' : 'Publish' }}
              </button> -->
              <!-- &ensp; <button class="btn btn-primary" (click)="confirmDeleteModal(element.id)">Delete</button>&ensp; -->
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <tr class="mat-row" *matNoDataRow>
            <!-- <td class="mat-cell" style="text-align: center" colspan="5">No data found</td> -->
            <td class="mat-cell" style="text-align: center" colspan="6">
              <i class="fas fa-cog fa-spin" style="font-size: 20px" [hidden]="!isLoading"></i>&ensp;<span
                style="font-size: 20px"
                >{{ isLoading ? 'Loading...' : 'No data found' }}</span
              >
            </td>
          </tr>
        </table>
        <mat-paginator
          [pageSizeOptions]="[50]"
          (page)="handlePageEvent($event)"
          [length]="totalPages"
          showFirstLastButtons
          aria-label="Select page"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<ng-template #conformPublishUnpublish let-modal>
  <div class="modal-body confirm-modalbody">
    <p>Are you sure want to {{ status === 'PUBLISHED' ? 'publish' : 'unpublish' }} this pack?</p>
  </div>
  <div class="modal-footer confirm-modalfooter">
    <button class="btn btn-pink btn-cancel" (click)="modal.dismiss('Cross click')">No</button>
    <button class="btn btn-pink" (click)="publishUnpublishEntry()" [disabled]="isLoading">
      <i class="fas fa-cog fa-spin" style="font-size: 18px" [hidden]="!isLoading"></i>{{ isLoading ? '&ensp;' : 'Yes' }}
    </button>
  </div>
</ng-template>

<ng-template #conformDelete let-modal>
  <div class="modal-body confirm-modalbody">
    <p>Are you sure want to delete this pack?</p>
  </div>
  <div class="modal-footer confirm-modalfooter">
    <button class="btn btn-pink btn-cancel" (click)="modal.dismiss('Cross click')">No</button>
    <button class="btn btn-pink" (click)="deletePack()" [disabled]="isLoading">
      <i class="fas fa-cog fa-spin" style="font-size: 18px" [hidden]="!isLoading"></i>{{ isLoading ? '&ensp;' : 'Yes' }}
    </button>
  </div>
</ng-template>
