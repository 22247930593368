import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from '@app/auth/typings/common-constants';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { CreateLandingPageCommand, UpdateLandingPageCommand } from './about';

@Injectable({
  providedIn: 'root',
})
export class AboutService {
  constructor(private httpClient: HttpClient) {}

  getAuthToken() {
    if (localStorage.getItem(AppConstants.AUTH_HEADER_KEY)) {
      let authToken: any = localStorage.getItem(AppConstants.AUTH_HEADER_KEY);
      return authToken;
    }
  }

  createLandingPage(registerDIYEntry: CreateLandingPageCommand): Observable<any> {
    let httpHeader = new HttpHeaders().set('Authorization', this.getAuthToken());
    return this.httpClient
      .post(
        environment.API_BASE_URL + environment.API_VERSION + 'landing-page/cms/createLandingPage',
        registerDIYEntry,
        { headers: httpHeader }
      )
      .pipe((res) => {
        return res;
      });
  }

  updateLandingPage(command: UpdateLandingPageCommand): Observable<any> {
    let httpHeader = new HttpHeaders().set('Authorization', this.getAuthToken());
    return this.httpClient
      .put(environment.API_BASE_URL + environment.API_VERSION + 'landing-page/cms/updateLandingPage', command, {
        headers: httpHeader,
      })
      .pipe((res) => {
        return res;
      });
  }

  getLandingPageDetailById(entryId: number): Observable<any> {
    let httpHeader = new HttpHeaders().set('Authorization', this.getAuthToken());
    return this.httpClient
      .get(environment.API_BASE_URL + environment.API_VERSION + 'landing-page/cms/listById?id=' + entryId, {
        headers: httpHeader,
      })
      .pipe((res) => {
        return res;
      });
  }

  getPaginationLandingPageList(pageNumber: number, pageSize: number, status: string): Observable<any> {
    let httpHeader = new HttpHeaders().set('Authorization', this.getAuthToken());
    let params: string;
    if (status === '') {
      params = 'page=' + pageNumber + '&size=' + pageSize;
    } else {
      params = 'status=' + status + 'page=' + pageNumber + '&size=' + pageSize;
    }
    return this.httpClient
      .get(environment.API_BASE_URL + environment.API_VERSION + 'landing-page/cms/landingPageLists?' + params, {
        headers: httpHeader,
      })
      .pipe((res) => {
        return res;
      });
  }

  stringTrim(str: string, length?: number): string {
    if (length) {
      if (str.length > length) {
        return str.substr(0, length) + '...';
      } else {
        return str;
      }
    } else {
      if (str.length > 20) {
        return str.substr(0, 20) + '...';
      } else {
        return str;
      }
    }
  }

  updateStatusById(command: any): Observable<any> {
    let httpHeader = new HttpHeaders().set('Authorization', this.getAuthToken());
    return this.httpClient
      .patch(
        environment.API_BASE_URL +
          environment.API_VERSION +
          'landing-page/cms/updateStatus?id=' +
          command.id +
          '&status=' +
          command.status,
        null,
        { headers: httpHeader }
      )
      .pipe((res) => {
        return res;
      });
  }

  deleteLandingPageById(entryId: number): Observable<any> {
    let httpHeader = new HttpHeaders().set('Authorization', this.getAuthToken());
    return this.httpClient
      .delete(environment.API_BASE_URL + environment.API_VERSION + 'landing-page/cms/deleteLandingPage?id=' + entryId, {
        headers: httpHeader,
      })
      .pipe((res) => {
        return res;
      });
  }
}
