import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { Shell } from '@app/shell/shell.service';
import { AboutComponent } from './about.component';
import { AppConstants } from '@app/auth/typings/common-constants';
import { PagesAuthGuard } from '@app/auth/pages-auth.guard';

const routes: Routes = [
  {
    path: '',
    component: AboutComponent,
    canActivate: [PagesAuthGuard],
    data: { title: marker('Landing Page | ' + AppConstants.APP_TITLE) },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AboutRoutingModule {}
