<div class="container">
  <div class="card mx-auto">
    <div class="card-body">
      <!-- <div [hidden]="!isLoading" class="loader-div">
        <i class="fas fa-cog fa-spin" style="text-align: center;" [hidden]="!isLoading"></i>
      </div> -->
      <div class="row">
        <div class="row">
          <div class="col-lg-11">
            <div class="login-container--header">
              <h2>Update Custom Pack</h2>
              <p>Please update custom pack.</p>
            </div>
          </div>
          <div class="col-md-1">
            <div class="close-icon" (click)="modalClose()"><span class="material-icons"> highlight_off </span></div>
          </div>
        </div>
        <div class="row loader-row">
          <app-loader [isLoading]="isLoading"></app-loader>
        </div>
        <form (ngSubmit)="updateCustomPack()" [formGroup]="updateCustomPackForm" novalidate *ngIf="!isLoading">
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter Left Title</mat-label>
                <input matInput placeholder="Enter Left Title" formControlName="flexiLeftText" required />
                <mat-error
                  *ngIf="
                    !updateCustomPackForm.controls['flexiLeftText'].valid ||
                    !updateCustomPackForm.controls['flexiLeftText'].untouched
                  "
                >
                  Left Title is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[0]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter Right Title One</mat-label>
                <input matInput placeholder="Enter Right Title One" formControlName="flexiRightText1" required />
                <mat-error
                  *ngIf="
                    !updateCustomPackForm.controls['flexiRightText1'].valid ||
                    !updateCustomPackForm.controls['flexiRightText1'].untouched
                  "
                >
                  Right Title One is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[1]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter Right Title Second</mat-label>
                <input matInput placeholder="Enter Right Title Second" formControlName="flexiRightText2" required />
                <mat-error
                  *ngIf="
                    !updateCustomPackForm.controls['flexiRightText2'].valid ||
                    !updateCustomPackForm.controls['flexiRightText2'].untouched
                  "
                >
                  Right Title Second is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[2]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter Save Up To Text</mat-label>
                <input matInput placeholder="Enter Save Up To Text" formControlName="saveUptoText" required />
                <mat-error
                  *ngIf="
                    !updateCustomPackForm.controls['saveUptoText'].valid ||
                    !updateCustomPackForm.controls['saveUptoText'].untouched
                  "
                >
                  Save Up To Text is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[3]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Select Plan Name(s)</mat-label>
                <mat-select
                  placeholder="Select Plan Name(s)"
                  multiple
                  required
                  [(ngModel)]="selectedValue"
                  formControlName="planIds"
                  name="planIds"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="searchTxt"
                      placeholderLabel="Search Plan Name(s)"
                      noEntriesFoundLabel="Not found"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let subscriptionDetail of filteredPlansMulti | async"
                    [value]="subscriptionDetail.SubscriptionPlanID"
                    (onSelectionChange)="optionedValue($event, subscriptionDetail)"
                  >
                    {{
                      subscriptionDetail.SubscriptionPlanID +
                        ' (' +
                        subscriptionDetail.SubscriptionPlanName +
                        ' - ' +
                        subscriptionDetail.SubscriptionPlanDurationName +
                        ' | Plan Category - ' +
                        subscriptionDetail.SubscriptionPlanCategory +
                        ' | Group No - ' +
                        subscriptionDetail.SubscriptionGroupNo +
                        ')'
                    }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    !updateCustomPackForm.controls['planIds'].valid ||
                    !updateCustomPackForm.controls['planIds'].untouched
                  "
                >
                  Plan Name(s) is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[3]" width="100%" height="100%" />
              </div>
            </div>
            <div class="selected-list" *ngIf="!isLoading && selecteSubscriptionList.length > 0">
              Your Selected Plans :
              <ul>
                <li *ngFor="let subscriptionDetail of selecteSubscriptionList; let i = index">
                  {{
                    subscriptionDetail.SubscriptionPlanID +
                      ' (' +
                      subscriptionDetail.SubscriptionPlanName +
                      ' - ' +
                      subscriptionDetail.SubscriptionPlanDurationName +
                      ' | Plan Category - ' +
                      subscriptionDetail.SubscriptionPlanCategory +
                      ' | Group No - ' +
                      subscriptionDetail.SubscriptionGroupNo +
                      ')'
                  }}
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Select Plan Name(s)</mat-label>
                <mat-select
                  formControlName="planIds"
                  [(ngModel)]="selectedValue"
                  name="planIds"
                  multiple
                  (selectionChange)="selectItem($event)"
                >
                  <div class="search-input">
                    <input
                      [(ngModel)]="searchTxt"
                      matInput
                      placeholder="Search Plan Name(s)"
                      [ngModelOptions]="{ standalone: true }"
                      class="planIds-name"
                    />
                  </div>
                  <mat-option
                    *ngFor="let subscriptionDetail of subscriptionList | search: searchTxt"
                    [value]="subscriptionDetail.SubscriptionPlanID"
                  >
                    {{
                      subscriptionDetail.SubscriptionPlanID +
                        ' (' +
                        subscriptionDetail.SubscriptionPlanName +
                        ' - ' +
                        subscriptionDetail.SubscriptionPlanDurationName +
                        ' | Plan Category - ' +
                        subscriptionDetail.SubscriptionPlanCategory +
                        ' | Group No - ' +
                        subscriptionDetail.SubscriptionGroupNo +
                        ')'
                    }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    !updateCustomPackForm.controls['planIds'].valid ||
                    !updateCustomPackForm.controls['planIds'].untouched
                  "
                >
                  Plan Name(s) is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[4]" width="100%" height="100%" />
              </div>
            </div>
            <div class="selected-list" *ngIf="!isLoading && selecteSubscriptionList.length > 0">
              Your Selected Plans :
              <ul>
                <li *ngFor="let subscriptionDetail of selecteSubscriptionList; let i = index">
                  {{
                    subscriptionDetail.SubscriptionPlanID +
                      ' (' +
                      subscriptionDetail.SubscriptionPlanName +
                      ' - ' +
                      subscriptionDetail.SubscriptionPlanDurationName +
                      ' | Plan Category - ' +
                      subscriptionDetail.SubscriptionPlanCategory +
                      ' | Group No - ' +
                      subscriptionDetail.SubscriptionGroupNo +
                      ')'
                  }}
                </li>
              </ul>
            </div>
          </div> -->
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter Button Text</mat-label>
                <input matInput placeholder="Enter Button Text" formControlName="buttonText" required />
                <mat-error
                  *ngIf="
                    !updateCustomPackForm.controls['buttonText'].valid ||
                    !updateCustomPackForm.controls['buttonText'].untouched
                  "
                >
                  Button Text is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[5]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Pick Color</mat-label>
                <input
                  matInput
                  (colorPickerChange)="selectedColor = $event"
                  [style.background]="selectedColor"
                  [cpOKButton]="pickColorButton"
                  cpOKButtonText="OK"
                  cpOKButtonClass="mat-raised-button mat-primary"
                  [colorPicker]="selectedColor"
                  [value]="selectedColor"
                  readonly
                />
                <mat-icon matSuffix>color_lens</mat-icon>
                <!-- <mat-error
                          *ngIf="selectedColor === '#a9a9a9'"
                        >
                          Color is required</mat-error
                        > -->
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[6]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter Display Order</mat-label>
                <input matInput placeholder="Enter Display Order" formControlName="displayOrder" required />
                <mat-error
                  *ngIf="
                    !updateCustomPackForm.controls['displayOrder'].valid ||
                    !updateCustomPackForm.controls['displayOrder'].untouched
                  "
                >
                  Display Order is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <!-- <div class="help-qq">
                        <mat-icon matSuffix>live_help</mat-icon>
                      </div>
                      <div class="help-icon">
                        <img [src]="toolTipList[0]" width="100%" height="100%" />
                      </div> -->
            </div>
          </div>
          <div class="row button-align">
            <button class="btn btn-primary w-100" type="submit" [disabled]="isLoading">
              <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
              <span translate>Update Pack</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
