<div class="container">
  <div class="card mx-auto">
    <div class="card-body">
      <div class="row">
        <div class="row">
          <div class="col-md-11">
            <div class="login-container--header">
              <h2>Create New Landing Page</h2>
              <p>Please create new landing page.</p>
            </div>
          </div>
          <div class="col-md-1">
            <div class="close-icon" (click)="modalClose()"><span class="material-icons"> highlight_off </span></div>
          </div>
        </div>
        <form (ngSubmit)="registerNewDIY()" [formGroup]="registerDIYForm" novalidate>
          <!-- <div *ngIf="foundErrorLogin" class="alert alert-danger" translate>Username or password incorrect.</div> -->
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter Name</mat-label>
                <input matInput placeholder="Enter Name" formControlName="name" required />
                <mat-error
                  *ngIf="!registerDIYForm.controls['name'].valid || !registerDIYForm.controls['name'].untouched"
                >
                  Name is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <!-- <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[0]" width="100%" height="100%" />
              </div> -->
            </div>
            <span style="position: relative; right: 40px; text-align: right" class="notes"
              ><i>Note : Please Add the prefix "C1" to the new campaign landing page(single plan).</i></span
            >
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Upload Top Banner Image</mat-label>
                <ngx-mat-file-input
                  formControlName="topBanner"
                  placeholder="Upload Image"
                  [accept]="'image/*'"
                  (change)="uploadTopBanner($event)"
                ></ngx-mat-file-input>
                <mat-icon matSuffix>backup</mat-icon>
                <!-- <mat-error
                  *ngIf="
                    !registerDIYForm.controls['topBanner'].valid || !registerDIYForm.controls['topBanner'].untouched
                  "
                >
                  Please upload a valid image
                </mat-error> -->
                <div
                  class="err-msg"
                  *ngIf="
                    registerDIYForm.controls['topBanner'].value &&
                    registerDIYForm.controls['topBanner'].value.files &&
                    registerDIYForm.controls['topBanner'].value.files[0].type.indexOf('image') === -1
                  "
                >
                  Please upload only image.
                </div>
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[1]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Upload Bottom Banner Image</mat-label>
                <ngx-mat-file-input
                  formControlName="bottomBanner"
                  placeholder="Upload Image"
                  [accept]="'image/*'"
                  (change)="uploadBottomBanner($event)"
                ></ngx-mat-file-input>
                <mat-icon matSuffix>backup</mat-icon>
                <!-- <mat-error
                  *ngIf="
                    !registerDIYForm.controls['bottomBanner'].valid ||
                    !registerDIYForm.controls['bottomBanner'].untouched
                  "
                >
                  Please upload a valid image
                </mat-error> -->
                <div
                  class="err-msg"
                  *ngIf="
                    registerDIYForm.controls['bottomBanner'].value &&
                    registerDIYForm.controls['bottomBanner'].value.files &&
                    registerDIYForm.controls['bottomBanner'].value.files[0].type.indexOf('image') === -1
                  "
                >
                  Please upload only image.
                </div>
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[2]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Select Plan Name(s)</mat-label>
                <mat-select
                  placeholder="Select Plan Name(s)"
                  multiple
                  required
                  [(ngModel)]="selectedValue"
                  formControlName="planIds"
                  name="planIds"
                  [sortComparator]="sortComparator"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="searchTxt"
                      placeholderLabel="Search Plan Name(s)"
                      noEntriesFoundLabel="Not found"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let subscriptionDetail of filteredPlansMulti | async"
                    [value]="subscriptionDetail.SubscriptionPlanID"
                    (onSelectionChange)="optionedValue($event, subscriptionDetail)"
                  >
                    {{
                      subscriptionDetail.SubscriptionPlanID +
                        ' (' +
                        subscriptionDetail.SubscriptionPlanName +
                        ' - ' +
                        subscriptionDetail.SubscriptionPlanDurationName +
                        ' | Plan Category - ' +
                        subscriptionDetail.SubscriptionPlanCategory +
                        ' | Group No - ' +
                        subscriptionDetail.SubscriptionGroupNo +
                        ')'
                    }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="!registerDIYForm.controls['planIds'].valid || !registerDIYForm.controls['planIds'].untouched"
                >
                  Plan Name(s) is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[3]" width="100%" height="100%" />
              </div>
            </div>
            <div class="selected-list" *ngIf="!isLoading && selectedSubscriptionList.length > 0">
              Your Selected Plans :
              <ul>
                <li *ngFor="let subscriptionDetail of selectedSubscriptionList; let i = index">
                  {{
                    subscriptionDetail.SubscriptionPlanID +
                      ' (' +
                      subscriptionDetail.SubscriptionPlanName +
                      ' - ' +
                      subscriptionDetail.SubscriptionPlanDurationName +
                      ' | Plan Category - ' +
                      subscriptionDetail.SubscriptionPlanCategory +
                      ' | Group No - ' +
                      subscriptionDetail.SubscriptionGroupNo +
                      ')'
                  }}
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Select Plan Name(s)</mat-label>
                <mat-select
                  formControlName="planIds"
                  [(ngModel)]="selectedValue"
                  name="planIds"
                  multiple
                  (selectionChange)="selectItem($event)"
                  [sortComparator]="sortComparator"
                >
                  <div class="search-input">
                    <input
                      [(ngModel)]="searchTxt"
                      matInput
                      placeholder="Search Plan Name(s)"
                      [ngModelOptions]="{ standalone: true }"
                      class="planIds-name"
                    />
                  </div>
                  <mat-option
                    *ngFor="let subscriptionDetail of subscriptionList | search: searchTxt"
                    [value]="subscriptionDetail.SubscriptionPlanID"
                  >
                    {{
                      subscriptionDetail.SubscriptionPlanID +
                        ' (' +
                        subscriptionDetail.SubscriptionPlanName +
                        ' - ' +
                        subscriptionDetail.SubscriptionPlanDurationName +
                        ' | Plan Category - ' +
                        subscriptionDetail.SubscriptionPlanCategory +
                        ')'
                    }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="!registerDIYForm.controls['planIds'].valid || !registerDIYForm.controls['planIds'].untouched"
                >
                  Plan Name(s) is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[3]" width="100%" height="100%" />
              </div>
            </div>
            <div class="selected-list" *ngIf="!isLoading && selectedSubscriptionList.length > 0">
              Your Selected Plans :
              <ul>
                <li *ngFor="let subscriptionDetail of selectedSubscriptionList; let i = index">
                  {{
                    subscriptionDetail.SubscriptionPlanID +
                      ' (' +
                      subscriptionDetail.SubscriptionPlanName +
                      ' - ' +
                      subscriptionDetail.SubscriptionPlanDurationName +
                      ' | Plan Category - ' +
                      subscriptionDetail.SubscriptionPlanCategory +
                      ')'
                  }}
                </li>
              </ul>
            </div>
          </div> -->
          <div class="row coupon-slider-toggle-btn">
            <mat-slide-toggle [(ngModel)]="isChecked" [ngModelOptions]="{ standalone: true }"
              >Slide here for the Add Multi Coupons</mat-slide-toggle
            >
          </div>
          <div class="row" *ngIf="!isChecked">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter a Coupon</mat-label>
                <input matInput placeholder="Enter a Coupon" formControlName="coupon" />
                <!-- <mat-error
                  *ngIf="!registerDIYForm.controls['coupon'].valid || !registerDIYForm.controls['coupon'].untouched"
                >
                  Coupon is required</mat-error
                > -->
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[4]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isChecked">
            <div class="mat-input-type multi-tags">
              <ngx-mat-tag-input
                appearance="fill"
                #aa
                (change)="change($event)"
                appearance="fill"
                placeholder="Add Multi Coupons"
              ></ngx-mat-tag-input>
              <small class="form-text text-muted"
                ><b>Note : </b>Enter Separate coupons with a comma or enter key & Enter <b>NAC</b>(Not Applicable
                Coupon) for empty coupon.</small
              >
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[4]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter a Coupon Validity</mat-label>
                <input matInput placeholder="Enter a Coupon Validity" formControlName="couponValidity" />
                <!-- <mat-error
                  *ngIf="!registerDIYForm.controls['couponValidity'].valid || !registerDIYForm.controls['couponValidity'].untouched"
                >
                  Coupon Validity is required</mat-error
                > -->
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[4]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter a Campaign Offer Message</mat-label>
                <input matInput placeholder="Enter a Campaign Offer Message" formControlName="campaignMessage" />
                <!-- <mat-error
                  *ngIf="!registerDIYForm.controls['campaignMessage'].valid || !registerDIYForm.controls['campaignMessage'].untouched"
                >
                  Campaign Offer Message is required</mat-error
                > -->
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[4]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter a Sub Campaign Offer Message</mat-label>
                <input matInput placeholder="Enter a Sub Campaign Offer Message" formControlName="subCampaignMessage" />
                <!-- <mat-error
                  *ngIf="!registerDIYForm.controls['subCampaignMessage'].valid || !registerDIYForm.controls['subCampaignMessage'].untouched"
                >
                  Sub Campaign Offer Message is required</mat-error
                > -->
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[4]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Pick Color</mat-label>
                <input
                  matInput
                  (colorPickerChange)="selectedColor = $event"
                  [style.background]="selectedColor"
                  [cpOKButton]="pickColorButton"
                  cpOKButtonText="OK"
                  cpOKButtonClass="mat-raised-button mat-primary"
                  [colorPicker]="selectedColor"
                  [value]="selectedColor"
                  readonly
                />
                <mat-icon matSuffix>color_lens</mat-icon>
              </mat-form-field>
            </div>
            <div class="indicator-help">
              <div class="help-qq">
                <mat-icon matSuffix>live_help</mat-icon>
              </div>
              <div class="help-icon">
                <img [src]="toolTipList[5]" width="100%" height="100%" />
              </div>
            </div>
          </div>
          <div class="row" *ngIf="imageUrl1.length === 0 ? (imageUrl2.length === 0 ? true : false) : false">
            <div class="mat-input-type">
              <mat-form-field appearance="fill">
                <mat-label>Enter a Video Url</mat-label>
                <input matInput placeholder="Enter a Video Url" formControlName="videoUrl" />
                <!-- <mat-error
                  *ngIf="!registerDIYForm.controls['videoUrl'].valid || !registerDIYForm.controls['videoUrl'].untouched"
                >
                  Video Url is required</mat-error
                > -->
              </mat-form-field>
            </div>
            <span class="notes"
              ><i
                >Note : Video Url Must Be Embed Video from Youtube Channel.
                <a href="https://support.google.com/youtube/answer/171780?hl=en" target="_blank">Click Here</a> for More
                Info</i
              ></span
            >
          </div>
          <div class="row errMsg" *ngIf="errorMessage !== null">
            {{ errorMessage }}
          </div>
          <div class="row button-align">
            <button class="btn btn-primary w-100" type="submit" [disabled]="isLoading">
              <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
              <span translate>Create Landing Page</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
